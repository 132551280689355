import React from 'react';
import "./index.scss";
import Footer from '../../components/Footer';

const TokenSaleAgreement = () => {
  const topRef = React.useRef(null);

  React.useEffect(() => {
    if(topRef.current) topRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }, []);

  return (
    <>
      <div className='route__agreement'>
        <h2 ref={topRef}>Crypto Token Sales Agreement</h2>
        <p>This Crypto Token Sales Agreement (the “Agreement”) is entered into on [insert date] (the “Effective Date”) by and between [insert name of company], a [insert state or country of incorporation] corporation (the “Company”) and the purchaser whose name and address are set forth on the signature page hereto (the “Purchaser”).</p>
        <h3>Purchase and Sale of Tokens</h3>
        <p>Subject to the terms and conditions of this Agreement, the Company agrees to sell to the Purchaser, and the Purchaser agrees to purchase from the Company, [insert number] tokens (the “Tokens”) for the purchase price of [insert price per token] per Token (the “Purchase Price”), for a total purchase price of [insert total purchase price].</p>
        <h3>Token Representations, Warranties, and Covenants</h3>
        <p>The Company represents, warrants, and covenants to the Purchaser that:</p>
        <ul>
          <li>The Tokens will be issued in accordance with applicable laws, rules, and regulations.</li>
          <li>The Tokens are free and clear of all liens, claims, and encumbrances of any kind.</li>
          <li>The Tokens do not constitute securities under applicable securities laws.</li>
        </ul>
        <h3>Purchaser Representations, Warranties, and Covenants</h3>
        <p>The Purchaser represents, warrants, and covenants to the Company that:</p>
        <ul>
          <li>The Purchaser has the authority and capacity to enter into this Agreement and to purchase the Tokens.</li>
          <li>The Purchaser is acquiring the Tokens for investment purposes only and not with a view to distribution or resale.</li>
          <li>The Purchaser is an accredited investor or is otherwise qualified under applicable securities laws to purchase the Tokens.</li>
        </ul>
        <h3>Closing and Delivery</h3>
        <p>The closing of the purchase and sale of the Tokens shall take place on [insert date] (the “Closing Date”). At the Closing, the Purchaser shall deliver the Purchase Price to the Company, and the Company shall deliver the Tokens to the Purchaser.</p>
        <h3>Governing Law and Dispute Resolution</h3>
        <p>This Agreement shall be governed by and construed in accordance with the laws of [insert state or country]. Any dispute arising out of or relating to this Agreement shall be resolved through arbitration in accordance with the rules of the American Arbitration Association.</p>
        <h3>Miscellaneous</h3>
        <ul>
          <li>This Agreement contains the entire agreement between the parties and supersedes all prior negotiations, understandings, and agreements between them.</li>
          <li>This Agreement may not be amended except by a written instrument executed by both parties.</li>
          <li>This Agreement shall be binding upon and inure to the benefit of the parties hereto and their respective successors and assigns.</li>
        </ul>
        <p>IN WITNESS WHEREOF, the parties have executed this Crypto Token Sales Agreement as of the date first written above.</p>
        <div>[insert signature block for Company and Purchaser]</div>
      </div>
      <Footer />
    </>
  );
}

export default TokenSaleAgreement
import React from 'react';
import "./index.scss";

import moment from "moment";
import axios from 'axios';

import Spinner from '../../../components/customComponents/Spinner';

import * as backendModule from "../../../modules/backendModule";

export default function Referrals(props) {
  const [data, setData] = React.useState();
  const [referralData, setReferralData] = React.useState({
    referrals: 0,
    transactions: 0,
    earned: 0
  });
  const [unpaidFilters, setUnpaidFilters] = React.useState(false);

  const getData = () => {
    setData();
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/referrals/getClientReferrals`,
      data: {
        filters: unpaidFilters ? [
          {name: "Status", op: "eq", value: 1},
          {name: "ReferralPaid", op: "eq", value: false}
        ] : []
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setData(res.data);
    }).catch(() => {
      setData(backendModule.genericError);
    });
  };

  React.useEffect(() => {
    if (data?.status !== "ok") return;

    let uniqueAddresses = [];
    let earnedAmount = 0;

    for (let item of data.data) {
      if (!uniqueAddresses.includes(item.Address)) uniqueAddresses.push(item.Address);
      if (item.Status === 1 && !item.ReferralPaid) {
        let earned = Number(item.OBCAmount) / 100 * 10;
        if (isNaN(earned)) earned = 0;
        earnedAmount += earned;
      };
    };

    setReferralData({
      earned: earnedAmount.toFixed(2),
      referrals: uniqueAddresses.length,
      transactions: data.data.length
    });
  }, [data]);

  React.useEffect(() => {
    getData();
  }, [unpaidFilters]);

  React.useEffect(() => {
    let header = document.querySelector(".component__header");
    if (header) header.style.display = "none";

    return () => header.style.display = null;
  }, []);

  return <div className="route__dashboardReferrals">
    <div className="route__dashboard__content__header">
      <div className="route__dashboard__content__header__buttons route__dashboardReferrals__header">
        <div onClick={() => setUnpaidFilters(false)} className={`route__dashboard__content__header__buttons__singleFilter filter ${!unpaidFilters ? "route__dashboard__content__header__buttons__singleFilter--active" : ""}`}>All transactions</div>
        <div onClick={() => setUnpaidFilters(true)} className={`route__dashboard__content__header__buttons__singleFilter filter ${unpaidFilters ? "route__dashboard__content__header__buttons__singleFilter--active" : ""}`}>Unpaid transactions</div>

        <div className="route__dashboard__content__header__buttons__single route__dashboardReferrals__header__addressButton">
          <p>{String(props.userData.Address).substring(0, 15)}...</p>
          <img src="/images/dashboard/profile.svg" alt="" />
        </div>
      </div>
    </div>


    <div className='route__dashboardReferrals__kpi'>
      <div className='route__dashboardReferrals__kpi__single'>
        <div className='route__dashboardReferrals__kpi__single__left'>
          <p className='route__dashboardReferrals__kpi__single__left__name'>Referrals</p>
          <p className='route__dashboardReferrals__kpi__single__left__value'>{referralData.referrals}</p>
        </div>
        <div className='route__dashboardReferrals__kpi__single__right'>
          <img src='/images/dashboard/referrals_accounts.svg' />
        </div>
      </div>

      <div className='route__dashboardReferrals__kpi__single'>
        <div className='route__dashboardReferrals__kpi__single__left'>
          <p className='route__dashboardReferrals__kpi__single__left__name'>Transactions</p>
          <p className='route__dashboardReferrals__kpi__single__left__value'>{referralData.transactions}</p>
        </div>
        <div className='route__dashboardReferrals__kpi__single__right'>
          <img src='/images/dashboard/referrals_transactions.svg' />
        </div>
      </div>

      <div className='route__dashboardReferrals__kpi__single'>
        <div className='route__dashboardReferrals__kpi__single__left'>
          <p className='route__dashboardReferrals__kpi__single__left__name'>Earned (unpaid)</p>
          <p className='route__dashboardReferrals__kpi__single__left__value'>{referralData.earned} <span style={{ color: "#666976" }}>USDT</span></p>
        </div>
        <div className='route__dashboardReferrals__kpi__single__right'>
          <img src='/images/dashboard/referrals_earned.svg' />
        </div>
      </div>
    </div>


    <p className='route__dashboardReferrals__heading'>
      <span>Transactions</span>
    </p>

    <ReferralTable
      headers={["Transaction Hash", "Amount", "Status", "Earned", "Date"]}
      content={data?.status === "ok" ? data.data : data?.status === 'error' ? "error" : 'spinner'}
    />
  </div>
};


const ReferralTable = (props) => {
  return <div className="component__dashboardTable">
    <div className="component__dashboardTable__head">
      {props.headers?.map((item, index) => {
        return <p className="component__dashboardTable__head__single" key={index}>
          {item}
        </p>
      })}
    </div>

    {props.content !== 'error' && props.content !== 'spinner' && props.content?.map((item, index) => {
      let earned = Number(item.USDTAmount) / 100 * 10;
      if (isNaN(earned)) earned = 0;

      return <div className="component__dashboardTable__content" key={index}>
        <p className="component__dashboardTable__content__single" >
          {item.TransactionHash}
        </p>
        <p className="component__dashboardTable__content__single" >
          {item.USDTAmount}
        </p>
        <p
          style={{ color: item.Status === 0 ? '#b48a0a' : item.Status === 1 ? '#2da00f' : item.Status === 2 ? '#b52e14' : 'gray' }}
          className="component__dashboardTable__content__single"
        >
          {item.Status === 0 ? 'Pending' : item.Status === 1 ? 'Approved' : item.Status === 2 ? 'Rejected' : 'Undefined status'}
        </p>
        <p className={`component__dashboardTable__content__single ${item.ReferralPaid ? "component__dashboardTable__content__single--active" : ""}`} >
          {earned.toFixed(2)}{`${item.ReferralPaid ? " - Paid" : ""}`}
        </p>
        <p className="component__dashboardTable__content__single" >
          {moment(item.createdAt).format('DD.MM.YYYY.')}
        </p>
      </div>
    })}
    {props.content === 'error' && <br></br>}
    {props.content === 'error' && <p style={{ color: 'red' }}>Can not get transactions data</p>}
    {props.content === 'spinner' && <Spinner align='center' color='#5956E9' style={{ marginTop: '30px' }} />}
    {props.content.length === 0 && <p style={{ marginTop: '20px' }}>There is nothing to show</p>}
  </div>
}
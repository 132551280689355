import React from "react";
import "./index.scss";
import Web3 from "web3";

import { useMetaMask } from "metamask-react";
import { animateBox } from "../../../modules/componentAnimation";
import Contract from "../../../modules/contract.json";
import YesNoModal from "../../../components/Modals/YesNoModal";

import CustomCheckbox from "../../../components/customComponents/CustomCheckbox";
import Spinner from "../../../components/customComponents/Spinner";

const SettingsAdmin = () => {
    const [data, setData] = React.useState();
    const [owner, setOwner] = React.useState();

    const mainMetamask = useMetaMask();

    const tokenPriceRef = React.useRef();

    const getContractInstance = () => {
        const web3 = new Web3(window.ethereum);
        return new web3.eth.Contract(Contract.abi, Contract.address);
    };

    const getCoinContractInstance = () => {
        const web3 = new Web3(window.ethereum);
        return new web3.eth.Contract(Contract.OBCAbi, Contract.OBCContract);
    };

    const enableAllowance = () => {
        animateBox({currentTarget: document.querySelector(".component__sidebar")}, <YesNoModal
        heading="Set allowance"
        text={`You are about to allow the contract to act on your behaf. this IS REQUIRED for the presale to work!`}
        isRightButtonNormal={true}
        buttonRightCallback={arg => {
            arg.spinner(true);
            arg.disabledAll(true);

            getCoinContractInstance().methods.approve(Contract.address, BigInt(50000000 * (10 ** 18))).send({from: mainMetamask.account}).then(() => {
                arg.spinner(false);
                arg.disabledAll(false);
                arg.close();
                getData();
            }).catch(() => {
                arg.spinner(false);
                arg.disabledAll(false);
                arg.errorMessage("There was an error while setting the allowance.");
            });
        }}
        buttonLeftCallback={(arg) => {
            arg.close();
            getData();
        }}
        />)
    };

    const changeSalesState = () => {
        animateBox({currentTarget: document.querySelector(".component__sidebar")}, <YesNoModal
        heading="Change sale status"
        text={`You are about to ${data.data.salesActive ? "deactivate" : "activate"} sales, are you sure?`}
        isRightButtonNormal={true}
        buttonRightCallback={arg => {
            arg.spinner(true);
            arg.disabledAll(true);

            getContractInstance().methods.toggleSaleStatus().send({from: mainMetamask.account}).then(() => {
                arg.spinner(false);
                arg.disabledAll(false);
                arg.close();
                getData();
            }).catch(() => {
                arg.spinner(false);
                arg.disabledAll(false);
                arg.errorMessage("There was an error while toggling the sales status!");
            });
        }}
        buttonLeftCallback={(arg) => {
            arg.close();
            getData();
        }}
        />)
    };

    const changeTokenPrice = () => {
        let price = Number(tokenPriceRef.current.value);
        if (isNaN(price)) return;
        price = price * 1000;

        animateBox({currentTarget: document.querySelector(".component__sidebar")}, <YesNoModal
        heading="Change token price"
        text={`You are about to change token price to ${Number(price / 1000).toFixed(4)}, are you sure?`}
        isRightButtonNormal={true}
        buttonRightCallback={arg => {
            arg.spinner(true);
            arg.disabledAll(true);

            getContractInstance().methods.setFixedTokenPrice(price).send({from: mainMetamask.account}).then(() => {
                arg.spinner(false);
                arg.disabledAll(false);
                arg.close();
                getData();
            }).catch(() => {
                arg.spinner(false);
                arg.disabledAll(false);
                arg.errorMessage("There was an error while setting the new price!");
            });
        }}
        buttonLeftCallback={(arg) => {
            arg.close();
            getData();
        }}
        />)
    };

    const getData = () => {
        setData();
        const contract = getContractInstance();
        const coin = getCoinContractInstance();

        let isSaleActive = false;
        let fixedTokenPrice = 0;
        let allowed = false;

        let promisesToSettle = [
            contract.methods.isSaleActive().call().then(res => {
                isSaleActive = !!res
            }).catch(() => isSaleActive = null),
            contract.methods.fixedTokenPrice().call().then(res => {
                fixedTokenPrice = (Number(res) / 1000).toFixed(4);
            }).catch(() => fixedTokenPrice = null),
            coin.methods.allowance(mainMetamask.account, Contract.address).call().then(res => {
                /* global BigInt */
                if (BigInt(res) > BigInt(10**18)) {
                    allowed = true;
                } else {
                    allowed = false;
                };
            }).catch(() => allowed = null)
        ];

        if (!owner) promisesToSettle.push(
            contract.methods.owner().call().then(res => {
                setOwner(String(res).toLowerCase())
            }).catch(() => setOwner("?"))
        );
        
        Promise.allSettled(promisesToSettle).then(() => null).catch(() => null).finally(() => {
            if (
                isSaleActive === null ||
                fixedTokenPrice === null ||
                allowed === null
            ) {
                setData({status: "error", data: "CONTRACT_ERROR"});
            } else {
                setData({
                    status: "ok",
                    data: {
                        price: fixedTokenPrice,
                        salesActive: isSaleActive,
                        sendingAllowed: allowed
                    }
                });
            };
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    if (!owner) return <div className="route__settingsAdmin">
        <Spinner style={{width: "64px", height: "64px"}} align="center" color="#5956E9" />
    </div>
    if (String(owner).toLowerCase() !== String(mainMetamask.account).toLowerCase()) return <div className="route__settingsAdmin">
        <p>You are not the owner of this contract!</p>
    </div>
    return <div className="route__settingsAdmin">
        {data ? <>
            {data.status === "ok" ? <>
                {!data.data.sendingAllowed && <p className="route__settingsAdmin__setting">
                    <button onClick={enableAllowance}>Enable spending allowance</button>
                </p>}

                <p className="route__settingsAdmin__setting">
                    Sale active: <CustomCheckbox onChange={e => {
                        if (e !== data?.data?.salesActive) changeSalesState();
                    }} defaultValue={data.data.salesActive} accent="#5956E9" />
                </p>

                <p className="route__settingsAdmin__setting">
                    Token price:
                    <input ref={tokenPriceRef} type="text" defaultValue={data.data.price} />
                    <button onClick={changeTokenPrice}>Update</button>
                </p>
            </> : <p>There was an error while fetching data!</p>}
        </> : <Spinner style={{width: "64px", height: "64px"}} align="center" color="#5956E9" />}
    </div>
};

export default SettingsAdmin;
import React from 'react';
import "./index.scss";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from '../../../modules/componentAnimation';
import axios from 'axios';
import moment from 'moment';
import Spinner from '../../../components/customComponents/Spinner';
import YesNoModal from '../../../components/Modals/YesNoModal';

export default function ReferralsAdmin() {
  const [allReferrals, setAllReferrals] = React.useState({ status: 'error', data: [] });
  const [spinner, setSpinner] = React.useState(false);

  const address = React.useRef();
  const token = React.useRef();

  React.useEffect(() => {
    let header = document.querySelector(".component__header");
    if (header) header.style.display = "none";

    return () => header.style.display = null;
  }, []);


  const getAllReferrals = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/referrals/getAllReferrals`,
      data: {

      }
    }).then(res => {
      console.log(res.data)
      if (res.data.status === 'ok') {
        setAllReferrals(res.data)
      }
    }).catch(null);

  }
  React.useEffect(() => {
    getAllReferrals()
  }, [])

  const addReferral = () => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/referrals/addReferral`,
      data: {
        Address: address.current.value,
        ReferralToken: token.current.value
      }
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllReferrals()
      }
    }).catch(null)
  }



  return (<>
    <div className='route__dashboardReferralsAdmin'>
      <div className='route__dashboardReferralsAdmin__head'>
        <p>Referral URL</p>
        <p>Referral ID</p>
        <p>USDT Ammount</p>
        <p></p>
        <p></p>
      </div>
      {(allReferrals.status === 'ok' && allReferrals.data?.length > 0) ? allReferrals.data.map((item, index) => {
        return <div className='route__dashboardReferralsAdmin__content'>
          <p>{backendModule.baseURL}?ref={item.ReferalKey}</p>
          <p>{item.ReferalAddress}</p>
          <p>{item.total?.USDTAmount?.toFixed(2)}

          </p>
          {item.total?.USDTAmount!==0 ? <button onClick={(e) => { animateBox(e, <PayModal ids={item.total.ID} getAll={getAllReferrals} />) }}>Pay</button>:<p></p>}
          <button onClick={(e) => { animateBox(e, <RemoveModal getAll={getAllReferrals} id={item.ID} />) }}>Delete</button>
        </div>

      }) : <p className='route__dashboardReferralsAdmin__info'>
        There is no available data
      </p>}

    </div>
    <div className='route__dashboardReferralsAdmin'>
      <div className='route__dashboardReferralsAdmin__form'>
        <p className='route__dashboardReferralsAdmin__form__head'>Add referral</p>
        <div>
          <label>Address:</label>
          <input ref={address} type={'text'} />
        </div>
        <div>
          <label>Referral token:</label>
          <input ref={token} type={'text'} />
        </div>
        <button onClick={() => { addReferral() }}>Add referral</button>
      </div>
    </div>
  </>
  )
}


const PayModal = (props) => {
  const payTransactions = () => {
    if(!Array.isArray(props.ids)) return;
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/transactions/payoutTransactions`,
      data: {
        IDs: props.ids
      }
    }).then(res => {
      console.log(res.data)
      if (res.data.status === 'ok') {
        props.getAll();
        props.onClose();
      }
    }).catch(null)
  }

  return <div className='component__removeReferal'>
    <div>
      <p>Make sure thet you have paid out the referrals before you click yes</p>
      <p>Clicking on yes will set all transactions as paid</p>
      <div className='component__removeReferal__buttons' style={{flexDirection: 'row-reverse'}}>
        <button onClick={() => { props.onClose() }}>No</button>
        <button onClick={() => { payTransactions() }}>Yes</button>

      </div>
    </div>


  </div>
}
const RemoveModal = (props) => {
  const removeReferral = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/referrals/removeReferral`,
      data: {
        ID: props.id
      }
    }).then(res => {
      console.log(res.data)
      if (res.data.status === 'ok') {
        props.getAll();
        props.onClose();
      }
    }).catch(null)
  }

  return <div className='component__removeReferal'>
    <div>
      <p>Are you sure you want to delete this token?</p>
      <div className='component__removeReferal__buttons'>
        <button onClick={() => { removeReferral() }}>Yes</button>
        <button onClick={() => { props.onClose() }}>No</button>
      </div>
    </div>


  </div>
}

import React from "react";
import "./index.scss";
import axios from "axios";
import Web3 from "web3";
import { useMetaMask } from "metamask-react";
import { useNavigate } from "react-router-dom";

import Contract from "../../modules/contract.json";
import * as backendModule from "../../modules/backendModule";
import * as urlModule from "../../modules/urlModule";
import animateModule from "../../modules/animateModule";
import { animateBox } from "../../modules/componentAnimation";

import YesNoModal from "../../components/Modals/YesNoModal";
import Footer from "../../components/Footer";
import Spinner from "../../components/customComponents/Spinner";
import ConnectWallet from "../../components/ConnectWallet";

import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const LandingPage = () => {
    const [usdtDecimals, setUsdtDecimals] = React.useState();
    const [tokenPrice, setTokenPrice] = React.useState();
    const [saleActive, setSaleActive] = React.useState(false);
    const [globalSpinner, setGlobalSpinner] = React.useState(false);
    const [tokensSold, setTokensSold] = React.useState();
    const [totalParticipants, setTotalParticipants] = React.useState();

    const mainMetaMask = useMetaMask();
    const usdtInputRef = React.useRef();
    const obcInputRef = React.useRef();
    const heroRef = React.useRef(null);
    const videoRef = React.useRef(null);

    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const handleUSDTInput = e => {
        let curValue = Number(e.currentTarget.value);
        if (isNaN(curValue)) return e.currentTarget.value = 0;
        if (tokenPrice !== "?") {
            obcInputRef.current.value = curValue / tokenPrice;
        } else {
            obcInputRef.current.value = "?";
        }
    };

    const handleOBCInput = e => {
        let curValue = Number(e.currentTarget.value);
        if (isNaN(curValue)) return e.currentTarget.value = 0;

        if (tokenPrice !== "?") {
            usdtInputRef.current.value = curValue * tokenPrice;
        } else {
            usdtInputRef.current.value = "?";
        }
    };

    const checkCanCalculateGas = async (fn) => {
        try {
            await fn.estimateGas();
            return true;
        } catch {
            return false;
        };
    };

    const handleError = () => {
        animateBox({ currentTarget: document.querySelector(".component__connectWallet") }, <YesNoModal
            buttonLeftHidden={true}
            buttonRightText="Ok"
            isRightButtonNormal={true}
            heading="Error"
            text="There was an error while processing your request, check if you have the required USDT before retrying."
        />);
    };

    const handleClick = async (e) => {
        if (!usdtDecimals) return;
        if (!tokenPrice || !saleActive) return;
        switch (mainMetaMask.status) {
            case "initializing":
            case "connecting":
                return;
            case "notConnected":
                mainMetaMask.connect().catch(() => null);
                break;
            case "unavailable":
                window.open("https://metamask.io/", "_blank");
                break;
            case "connected":
                let curValue = Number(usdtInputRef.current.value);
                if (isNaN(curValue)) return;
                if (curValue < 1) return;
                if (curValue < 100) return animateBox(e, <WarningMessageModal message='Minimum purchase must be USD 100' />);
                if (mainMetaMask.chainId !== Contract.contractNetwork) return animateBox(e, <WarningMessageModal message='It looks like you are not on the right network. You must be on the Ethereum Mainnet.' />);
                setGlobalSpinner(true);

                /* global BigInt */
                const usdtApproveFn = window.usdt.methods.approve(Contract.address, BigInt(curValue * (10 ** usdtDecimals)));
                const buyTokensFn = window.contract.methods.buyTokens(mainMetaMask.account, BigInt(curValue * (10 ** usdtDecimals)));

                let canCalculateGasUSDT = await checkCanCalculateGas(usdtApproveFn);
                let canCalculateGasToken = await checkCanCalculateGas(buyTokensFn);

                let usdtData = { from: mainMetaMask.account };
                let tokenData = { from: mainMetaMask.account };

                if (!canCalculateGasUSDT) usdtData.gas = Contract.contractNetwork === "0x1" ? 200000 : 999999;
                if (!canCalculateGasToken) tokenData.gas = Contract.contractNetwork === "0x1" ? 200000 : 999999;

                let curParams = urlModule.getParamsFromURLObject(window.location.toString());


                usdtApproveFn.send(usdtData)
                    .then(() => null)
                    .catch(() => null)
                    .finally(async () => {
                        await new Promise(r => setTimeout(() => r(), 1000));
                        buyTokensFn.send(tokenData)
                            .on("transactionHash", function (data) {
                                axios({
                                    method: "POST",
                                    url: `${backendModule.backendURL}/transactions/addTransaction`,
                                    data: {
                                        Address: mainMetaMask.account,
                                        TransactionHash: data,
                                        Ref: curParams.ref
                                    }
                                })
                                    .then(() => null)
                                    .catch(() => null);
                            })
                            .then(txt => {
                                setGlobalSpinner(false);
                                animateBox({ currentTarget: document.querySelector(".component__connectWallet") }, <ThankYou navigateFn={animateNavigate} />);
                            }).catch(() => {
                                setTimeout(() => handleError(), 1000);
                                setGlobalSpinner(false);
                            });
                    });
                break;
            default: break;
        };
    };

    let pausedAtTime = 0;
    const playVideo = () => {
        if (videoRef.current.paused) {
            videoRef.current.currentTime = pausedAtTime;
            videoRef.current.play();
        }
        else {
            videoRef.current.pause();
            pausedAtTime = videoRef.current.currentTime;
            videoRef.current.load();
        }
    }

    const addToMetamask = () => {
        try {
            window.ethereum.request({
                method: "wallet_watchAsset",
                params: {
                    type: "ERC20",
                    options: {
                        address: Contract.OBCContract,
                        symbol: "OBC",
                        decimals: 18,
                        image: "https://ipo.obscale.com/images/logo_coin.svg"
                    }
                }
            });
        } catch { };
    };

    React.useEffect(() => {
        const web3 = new Web3(window.ethereum);
        window.contract = new web3.eth.Contract(Contract.abi, Contract.address);
        window.usdt = new web3.eth.Contract(Contract.USDTAbi, Contract.USDTContract);

        window.contract.methods.fixedTokenPrice().call().then(price => {
            setTokenPrice((Number(price) / 1000).toFixed(4));
        }).catch(() => {
            setTokenPrice("?");
        });
        window.contract.methods.isSaleActive().call().then(sale => {
            setSaleActive(!!sale);
        }).catch(() => {
            setSaleActive(false);
        });
        window.contract.methods.tokensSold().call().then(tk => {
            setTokensSold(tk / (10 ** 18));
        }).catch(() => {
            setTokensSold(0);
        });
        window.usdt.methods.decimals().call().then((dec) => {
            setUsdtDecimals(Number(dec));
        }).catch(() => {
            setUsdtDecimals("?");
        });
    }, []);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/transactions/getUniqueParticipants`
        }).then(res => {
            if (res.data.status === "ok") {
                setTotalParticipants(res.data.data);
            } else {
                setTotalParticipants("?");
            };
        }).catch(() => {
            setTotalParticipants("?");
        });
    }, []);

    return <div className="route__landingPage">
        <div className={`route__landingPage__spinner ${globalSpinner ? "route__landingPage__spinner--active" : ""}`}>
            <Spinner style={{ width: "128px", height: "128px" }} color="#5956E9" />
        </div>
        <div className="route__landingPage__hero">
            <div className="route__landingPage__hero__wrapper">
                <div ref={heroRef} className="route__landingPage__hero__wrapper__left">
                    <div className="route__landingPage__hero__wrapper__left__prices">
                        <div className="route__landingPage__hero__wrapper__left__prices__item">
                            <img src="images/landing_crm.svg" alt="" className="route__landingPage__hero__wrapper__left__prices__item__img" />
                            <div className="route__landingPage__hero__wrapper__left__prices__item__center">
                                <h1>Scale CRM</h1>
                                <p>Call center platform</p>
                            </div>
                        </div>
                        <div className="route__landingPage__hero__wrapper__left__prices__item">
                            <img src="images/landing_pharmscale.svg" alt="" className="route__landingPage__hero__wrapper__left__prices__item__img" />
                            <div className="route__landingPage__hero__wrapper__left__prices__item__center">
                                <h1>Pharm Scale</h1>
                                <p>Pharmaceutical manufacturing</p>
                            </div>
                        </div>
                        <div className="route__landingPage__hero__wrapper__left__prices__item">
                            <img src="images/landing_bioplar.svg" alt="" className="route__landingPage__hero__wrapper__left__prices__item__img" />
                            <div className="route__landingPage__hero__wrapper__left__prices__item__center">
                                <h1>BIOPLAR</h1>
                                <p>Capsule supplements</p>
                            </div>
                        </div>
                    </div>
                    <video style={{ cursor: 'pointer' }} ref={videoRef} poster="images/landing_videoPoster.png" onClick={() => playVideo()} className="route__landingPage__hero__wrapper__left__img">
                        <source src="videos/OBCvideo.mp4" type="video/mp4" />
                    </video>
                    <h1 className="route__landingPage__hero__wrapper__left__h1">Invest in <br></br> <span>Obscale token</span></h1>
                    <p className="route__landingPage__hero__wrapper__left__bottomP">
                        OBSCALE Token is issued for the <strong>purposes of crowdfunding</strong> for expansion to <strong>two</strong> <br></br> <strong>markets Poland and Italy</strong>. All issued tokens <strong>represent 25% of our companies profits.</strong>
                    </p>
                </div>
                <div className="route__landingPage__hero__wrapper__right">
                    {mainMetaMask.status !== 'unavailable' && <h1 className="route__landingPage__hero__wrapper__right__h1">First Stage</h1>}
                    {mainMetaMask.status !== 'unavailable' && <div className="route__landingPage__hero__wrapper__right__topStatus">
                        <p className="route__landingPage__hero__wrapper__right__topStatus__p">Presale status</p>
                        <div className="route__landingPage__hero__wrapper__right__topStatus__status">
                            <div style={{ background: saleActive ? '#27D17F' : '#cf6363fa' }}></div>
                            <p>{saleActive ? 'Open' : 'Closed'}</p>
                        </div>
                    </div>}
                    {mainMetaMask.status !== 'unavailable' && <h2 className="route__landingPage__hero__wrapper__right__h2">{tokensSold === undefined || tokensSold === null ? <Spinner style={{ width: "16px", height: "16px" }} color="#5956E9" /> : (isNaN(tokenPrice * tokensSold) ? Intl.NumberFormat("en-us", { style: "currency", currency: "USD", minimumFractionDigits: 2 }).format(0) : Intl.NumberFormat("en-us", { style: "currency", currency: "USD", minimumFractionDigits: 2 }).format(tokenPrice * tokensSold))}</h2>}
                    {mainMetaMask.status !== 'unavailable' && <p className="route__landingPage__hero__wrapper__right__grayP">{tokensSold === undefined || tokensSold === null ? <Spinner style={{ width: "16px", height: "16px" }} color="#5956E9" /> : ((100 / (1500000 / tokenPrice)) * tokensSold).toFixed(2)}% of minimum goal raised</p>}
                    {mainMetaMask.status !== 'unavailable' && <div className="route__landingPage__hero__wrapper__right__progressBar">
                        <div className="route__landingPage__hero__wrapper__right__progressBar__progress" style={{
                            width: tokensSold ? `${(100 / (1500000 / tokenPrice)) * tokensSold}%` : "0%"
                        }}></div>
                    </div>}
                    {mainMetaMask.status !== 'unavailable' && <p className="route__landingPage__hero__wrapper__right__smallP">$1,500,000</p>}
                    {mainMetaMask.status !== 'unavailable' && <div className="route__landingPage__hero__wrapper__right__box">
                        <p className="route__landingPage__hero__wrapper__right__box__grayP">Participants</p>
                        <p className="route__landingPage__hero__wrapper__right__box__p gap">{(totalParticipants !== null && totalParticipants !== undefined) ? totalParticipants : <Spinner color="#5956E9" style={{ width: "16px", height: "16px" }} />}</p>
                        <p className="route__landingPage__hero__wrapper__right__box__grayP">Current price</p>
                        <p className="route__landingPage__hero__wrapper__right__box__p">
                            <span>1 OBC = </span>
                            <span>{tokenPrice ? `${tokenPrice} USDT` : <Spinner color="#5956E9" style={{ width: "16px", height: "16px" }} />}</span>
                        </p>
                    </div>}
                    {mainMetaMask.status === 'unavailable' && window.innerWidth > 1000 && <div className="route__landingPage__hero__wrapper__right__box noMetaMask">
                        <p className="noMetaMask__noMetaMaskMessage">
                            You need to install MetaMask in order to see the token information
                        </p>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}><ConnectWallet /></div>
                    </div>}
                    {mainMetaMask.status === 'unavailable' && window.innerWidth <= 1000 && <div className="route__landingPage__hero__wrapper__right__box noMetaMask">
                        <p className="noMetaMask__noMetaMaskMessage">
                            You need to install MetaMask in order to see the token information, and use the MetaMask browser within the app
                        </p>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}><ConnectWallet /></div>
                    </div>}
                    {mainMetaMask.status !== 'unavailable' && <div className="route__landingPage__hero__wrapper__right__input">
                        <input ref={usdtInputRef} onChange={handleUSDTInput} type='text' placeholder="1000" className="route__landingPage__hero__wrapper__right__input__field" />
                        <div className="route__landingPage__hero__wrapper__right__input__mark">
                            <img className="route__landingPage__hero__wrapper__right__input__mark__coinImg" src="images/landing_usdt.svg" alt="" />
                            <p>USDT</p>
                        </div>
                    </div>}
                    {mainMetaMask.status !== 'unavailable' && <div className="route__landingPage__hero__wrapper__right__input">
                        <input ref={obcInputRef} onChange={handleOBCInput} type='text' placeholder="1000" className="route__landingPage__hero__wrapper__right__input__field" />
                        <div className="route__landingPage__hero__wrapper__right__input__mark">
                            <img className="route__landingPage__hero__wrapper__right__input__mark__coinImg" src="images/logo_coin.svg" alt="" />
                            <p>OBC</p>
                        </div>
                    </div>}
                    {mainMetaMask.status !== 'unavailable' && <button onClick={handleClick} className="route__landingPage__hero__wrapper__right__button">{(() => {
                        if (!saleActive) return "Sale not yet active!";
                        switch (mainMetaMask.status) {
                            case "notConnected":
                                return "Connect Wallet";
                            case "initializing":
                            case "connecting":
                                return <Spinner style={{ width: "16px", height: "16px" }} color="white" />
                            case "unavailable":
                                return "Install MetaMask";
                            case 'connected':
                                return "Purchase";
                            default: break;
                        };
                    })()}</button>}
                    <div onClick={addToMetamask} className="route__landingPage__hero__wrapper__right__button route__landingPage__hero__wrapper__right__button--metamask">
                        <img src="/images/metamask_logo.svg" />
                        Add token to metamask
                    </div>
                    <div className="route__landingPage__hero__wrapper__right__profitShare">
                        <img src="images/landing_25p.svg" alt="" />
                        <div className="route__landingPage__hero__wrapper__right__profitShare__right">
                            <p className="route__landingPage__hero__wrapper__right__profitShare__right__smallP">Security token</p>
                            <p className="route__landingPage__hero__wrapper__right__profitShare__right__bigP">SHARE COMPANY PROFIT</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="route__landingPage__hero__stages">
                <p className="route__landingPage__hero__stages__p">The presale of OBSCALE token will be in 4 rounds: </p>
                <div className="route__landingPage__hero__stages__wrapper">
                    <div className="route__landingPage__hero__stages__wrapper__item">
                        <img src="images/landing_presale.svg" alt="" />
                        <div className="route__landingPage__hero__stages__wrapper__item__right">
                            <p className="route__landingPage__hero__stages__wrapper__item__right__p">
                                Start of presale 15.11.2023 <br></br> with the price of 0,037 USD
                            </p>
                        </div>
                    </div>
                    <div className="route__landingPage__hero__stages__wrapper__item">
                        <img src="images/landing_round2.svg" alt="" />
                        <div className="route__landingPage__hero__stages__wrapper__item__right">
                            <p className="route__landingPage__hero__stages__wrapper__item__right__p">
                                Phase. Start of 15.12.2023 with <br></br> the price of 0,04 USD
                            </p>
                        </div>
                    </div>
                    <div className="route__landingPage__hero__stages__wrapper__item">
                        <img src="images/landing_round3.svg" alt="" />
                        <div className="route__landingPage__hero__stages__wrapper__item__right">
                            <p className="route__landingPage__hero__stages__wrapper__item__right__p">
                                Phase. Start of 15.01.2024 with <br></br> the price of 0,045 USD
                            </p>
                        </div>
                    </div>
                    <div className="route__landingPage__hero__stages__wrapper__item">
                        <img src="images/landing_round4.svg" alt="" />
                        <div className="route__landingPage__hero__stages__wrapper__item__right">
                            <p className="route__landingPage__hero__stages__wrapper__item__right__p">
                                Phase. Start of 15.02.2024 with<br></br> the price of 0,05 USD
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="route__landingPage__sectionTwo">
            <h1 className="route__landingPage__sectionTwo__h1">100% secure</h1>
            <div className="route__landingPage__sectionTwo__wrapper">
                <div className="route__landingPage__sectionTwo__wrapper__block">
                    <img className="route__landingPage__sectionTwo__wrapper__block__img" src="images/landing_regulatedIcon.svg" alt="" />
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">Regulated</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        Bosnia based and European regulated crypto & securities platform
                    </p>
                </div>
                <div className="route__landingPage__sectionTwo__wrapper__divider"></div>
                <div className="route__landingPage__sectionTwo__wrapper__block">
                    <img className="route__landingPage__sectionTwo__wrapper__block__img" src="images/landing_safe.svg" alt="" />
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">Safe and secure</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        Funds secured in offline wallets. Fully compliant with European data, IT and money laundering security standards
                    </p>
                </div>
                <div className="route__landingPage__sectionTwo__wrapper__divider"></div>
                <div className="route__landingPage__sectionTwo__wrapper__block">
                    <img className="route__landingPage__sectionTwo__wrapper__block__img" src="images/landing_trusted.svg" alt="" />
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">Trusted</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        98,5% happy users all over the world. Excellent Trust pilot rating.
                    </p>
                </div>
            </div>
        </div>
        <div className="route__landingPage__sectionThree">
            <small className="route__landingPage__sectionThree__small">INVEST</small>
            <h1 className="route__landingPage__sectionThree__h1">All your investments. <br></br> All on <span>Obscale</span>.</h1>
            <div className="route__landingPage__sectionThree__gridbox">
                <div className="route__landingPage__sectionThree__gridbox__item">
                    <h1 className="route__landingPage__sectionThree__gridbox__item__h1">Obscale</h1>
                    <p className="route__landingPage__sectionThree__gridbox__item__p">
                        Obscale is a software development company specializing in creating scalable and customizable solutions for businesses of all sizes.
                    </p>
                    <img className="route__landingPage__sectionThree__gridbox__item__img" src="images/landing_code.png" alt="" />
                </div>
                <div className="route__landingPage__sectionThree__gridbox__item">
                    <h1 className="route__landingPage__sectionThree__gridbox__item__h1">Pharm Scale</h1>
                    <p className="route__landingPage__sectionThree__gridbox__item__p">
                        Pharmaceutical scale nutraceutical manufacturing refers to the process of producing dietary supplements using equipment and procedures that adhere to pharmaceutical industry standards. This ensures the purity, potency, and quality of the final product, and enables it to meet regulatory requirements for safety and efficacy.
                    </p>
                </div>
                <div className="route__landingPage__sectionThree__gridbox__item">
                    <h1 className="route__landingPage__sectionThree__gridbox__item__h1">Scale Lead</h1>
                    <p className="route__landingPage__sectionThree__gridbox__item__p">
                        Scale Lead is an affiliate platform that provides a comprehensive suite of tools and resources to help businesses manage and optimize their affiliate programs
                    </p>
                    <img className="route__landingPage__sectionThree__gridbox__item__img" src="images/landing_charts.png" alt="" />
                </div>
                <div className="route__landingPage__sectionThree__gridbox__item">
                    <h1 className="route__landingPage__sectionThree__gridbox__item__h1">BioPlar</h1>
                    <p className="route__landingPage__sectionThree__gridbox__item__p">
                        BioPlar is a 100% natural health brand that offers a range of capsule supplements to support various aspects of health, including hemorrhoids, prostate health, potency, improved vision, and high blood pressure.
                    </p>
                </div>
                <div className="route__landingPage__sectionThree__gridbox__item">
                    <h1 className="route__landingPage__sectionThree__gridbox__item__h1">Scale CRM</h1>
                    <p className="route__landingPage__sectionThree__gridbox__item__p">
                        A scalable CRM call center platform is a software solution that enables businesses to efficiently manage their customer interactions.
                    </p>
                    <img className="route__landingPage__sectionThree__gridbox__item__img" src="images/landing_headphones.svg" alt="" />
                </div>
                <div className="route__landingPage__sectionThree__gridbox__item">
                    <h1 className="route__landingPage__sectionThree__gridbox__item__h1">CupidCoast</h1>
                    <p className="route__landingPage__sectionThree__gridbox__item__p">
                        Is a modern dating platform that connects individuals based on their shared interests, values, and preferences, using a range of features such as advanced search filters and personality assessments. Qupid Coast also offers a safe and secure environment for users to interact, with measures such as profile verification and anti-fraud systems in place.
                    </p>
                </div>
            </div>
            <p className="route__landingPage__sectionThree__lastP">
                Investing in our company can provide a great opportunity for long-term growth and profitability, as they are often focused on innovative solutions that meet the needs of modern businesses and consumers. Additionally, these companies are often well-positioned to take advantage of emerging trends and technologies, making them a potentially lucrative investment for those looking to diversify their portfolio.
            </p>
        </div>
        <div className="route__landingPage__sectionTwo">
            <h1 className="route__landingPage__sectionTwo__h1">Benefits</h1>
            <div className="route__landingPage__sectionTwo__wrapper">
                <div className="route__landingPage__sectionTwo__wrapper__block middleSection">
                    <img className="route__landingPage__sectionTwo__wrapper__block__img" src="images/landing_money.svg" alt="" />
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">Strong growth potential</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        Our companies have a proven track record of growth and are well-positioned to take advantage of emerging trends and technologies, providing a potential for long-term profitability.
                    </p>
                </div>
                <div className="route__landingPage__sectionTwo__wrapper__divider"></div>
                <div className="route__landingPage__sectionTwo__wrapper__block middleSection">
                    <img className="route__landingPage__sectionTwo__wrapper__block__img" src="images/landing_innovative.svg" alt="" />
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">Innovative solutions</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        Our companies are focused on developing innovative solutions that meet the needs of modern businesses and consumers, providing a competitive edge in the market.
                    </p>
                </div>
                <div className="route__landingPage__sectionTwo__wrapper__divider"></div>
                <div className="route__landingPage__sectionTwo__wrapper__block middleSection paddingRightSmall">
                    <img className="route__landingPage__sectionTwo__wrapper__block__img" src="images/landing_management.svg" alt="" />
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">Experienced management</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        Our companies are led by experienced and knowledgeable management teams who have a track record of success, providing confidence in the company's ability to execute on its strategic objectives.
                    </p>
                </div>
                <div className="route__landingPage__sectionTwo__wrapper__block middleSection">
                    <img className="route__landingPage__sectionTwo__wrapper__block__img" src="images/landing_diversification.svg" alt="" />
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">Diversification</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        Investing in our companies can provide diversification benefits to a portfolio, reducing overall risk and potentially enhancing returns.
                    </p>
                </div>
                <div className="route__landingPage__sectionTwo__wrapper__divider"></div>
                <div className="route__landingPage__sectionTwo__wrapper__block middleSection">
                    <img className="route__landingPage__sectionTwo__wrapper__block__img" src="images/landing_impact.svg" alt="" />
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">Social impact</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        Our companies are committed to making a positive social impact, whether through sustainable business practices, charitable initiatives, or other means.
                    </p>
                </div>
                <div className="route__landingPage__sectionTwo__wrapper__divider"></div>
                <div className="route__landingPage__sectionTwo__wrapper__block middleSection paddingRightSmall">
                    <img className="route__landingPage__sectionTwo__wrapper__block__img" src="images/landing_transparency.svg" alt="" />
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">Transparency and accountability</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        Our companies prioritize transparency and accountability, providing investors with access to detailed financial and operational information, and adhering to rigorous ethical standards.
                    </p>
                </div>
            </div>
        </div>
        <div className="route__landingPage__sectionFive">
            <small className="route__landingPage__sectionFive__small">FEATURES</small>
            <h1 className="route__landingPage__sectionFive__h1">Discover key qualities of our companies</h1>
            <p className="route__landingPage__sectionFive__p">All companies</p>
            <div className="route__landingPage__sectionFive__wrapper">
                <div className="route__landingPage__sectionFive__wrapper__item">
                    <div className="route__landingPage__sectionFive__wrapper__item__header">
                        <img src="images/landing_crm2.svg" alt="" />
                        <div className="route__landingPage__sectionFive__wrapper__item__header__right">
                            <h1 className="route__landingPage__sectionFive__wrapper__item__header__right__h1">Scale CRM</h1>
                            <p className="route__landingPage__sectionFive__wrapper__item__header__right__p">Call center platform</p>
                        </div>
                    </div>
                    <div className="route__landingPage__sectionFive__wrapper__item__body">
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Personalized customer experience</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Multi-channel support</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Knowledgeable and friendly agents</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Quick and easy ordering process</p>
                        </span>
                        <button onClick={() => window.open('https://scale-crm.com', '_blank')} className="route__landingPage__sectionFive__wrapper__item__body__button">Explore</button>
                    </div>
                </div>
                <div className="route__landingPage__sectionFive__wrapper__item">
                    <div className="route__landingPage__sectionFive__wrapper__item__header">
                        <img src="images/landing_pharmscale.svg" alt="" />
                        <div className="route__landingPage__sectionFive__wrapper__item__header__right">
                            <h1 className="route__landingPage__sectionFive__wrapper__item__header__right__h1">Pharm Scale</h1>
                            <p className="route__landingPage__sectionFive__wrapper__item__header__right__p">Nutra manufacturing facility</p>
                        </div>
                    </div>
                    <div className="route__landingPage__sectionFive__wrapper__item__body">
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Experienced pharmacists</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>High-quality products</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Extensive quality control system</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>ISO, GMP, HALAL & HACCP certified</p>
                        </span>
                        <button onClick={() => window.open('https://pharmscale.com', '_blank')} className="route__landingPage__sectionFive__wrapper__item__body__button">Explore</button>
                    </div>
                </div>
                <div className="route__landingPage__sectionFive__wrapper__item">
                    <div className="route__landingPage__sectionFive__wrapper__item__header">
                        <img src="images/landing_bioplar.png" alt="" />
                        <div className="route__landingPage__sectionFive__wrapper__item__header__right">
                            <h1 className="route__landingPage__sectionFive__wrapper__item__header__right__h1">Bio Plar</h1>
                            <p className="route__landingPage__sectionFive__wrapper__item__header__right__p">Pharmaceuticals</p>
                        </div>
                    </div>
                    <div className="route__landingPage__sectionFive__wrapper__item__body">
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Premium ingredients</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>100% natural products</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>For various conditions</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Special discounts for larger quantities</p>
                        </span>
                        <button onClick={() => window.open('https://bioplar.com', '_blank')} className="route__landingPage__sectionFive__wrapper__item__body__button">Explore</button>
                    </div>
                </div>
                <div className="route__landingPage__sectionFive__wrapper__item">
                    <div className="route__landingPage__sectionFive__wrapper__item__header">
                        <img src="images/landing_scalelead.ico" alt="" />
                        <div className="route__landingPage__sectionFive__wrapper__item__header__right">
                            <h1 className="route__landingPage__sectionFive__wrapper__item__header__right__h1">Scale Lead</h1>
                            <p className="route__landingPage__sectionFive__wrapper__item__header__right__p">Affiliate marketing</p>
                        </div>
                    </div>
                    <div className="route__landingPage__sectionFive__wrapper__item__body">
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Best CPA offers on the market</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Best conversion rates</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Dedicated affiliate manager</p>
                        </span>
                        <span className="route__landingPage__sectionFive__wrapper__item__body__line">
                            <img src="images/landing_tickBlue.svg" alt="" />
                            <p>Customizable payouts</p>
                        </span>
                        <button onClick={() => window.open('https://scale-lead.com', '_blank')} className="route__landingPage__sectionFive__wrapper__item__body__button">Explore</button>
                    </div>
                </div>
            </div>
            <div className="route__landingPage__sectionFive__end">
                <p className="route__landingPage__sectionFive__end__p">Past performance is not an indication of future performance</p>
            </div>
        </div>
        <div className="route__landingPage__sectionSix">
            <h1 className="route__landingPage__sectionSix__h1">How to buy</h1>
            <div className="route__landingPage__sectionSix__wrapper">
                <div className="route__landingPage__sectionSix__wrapper__left">
                    <p className="route__landingPage__sectionSix__wrapper__left__step">Step 1</p>
                    <p className="route__landingPage__sectionSix__wrapper__left__action">Connect your Wallet</p>
                    <p className="route__landingPage__sectionSix__wrapper__left__description">Use Metamask to connect your wallet in seconds.</p>
                    <p className="route__landingPage__sectionSix__wrapper__left__step">Step 2</p>
                    <p className="route__landingPage__sectionSix__wrapper__left__action">Confirm Transaction</p>
                    <p className="route__landingPage__sectionSix__wrapper__left__description">You will need to have some small ammount of ETH in your wallet to cover gas transaction fees of blockchain network.</p>
                    <p className="route__landingPage__sectionSix__wrapper__left__step">Step 3</p>
                    <p className="route__landingPage__sectionSix__wrapper__left__action">Claim Tokens</p>
                    <p className="route__landingPage__sectionSix__wrapper__left__description">Congratulations! You tokens should arrive in your metamask wallet in minutes.</p>
                    <div className="route__landingPage__sectionSix__wrapper__left__buttons">
                        <button
                            className="route__landingPage__sectionSix__wrapper__left__buttons__buttonSmall"
                            onClick={() => heroRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })}
                        >
                            BUY NOW
                        </button>
                    </div>
                </div>
                <img src="images/landing_metamask.png" alt="" />
            </div>
            <div className="route__landingPage__sectionSix__bottom">
                <div className="route__landingPage__sectionSix__bottom__left">
                    <h1 className="route__landingPage__sectionSix__bottom__left__h1">Buy More, Get More!</h1>
                    <p className="route__landingPage__sectionSix__bottom__left__p">
                        Maximize your rewards with every purchase! Join the exclusive Platinum Member’s Club and receive amazing benefits starting from just $100. The more you spend , the more benefits you earn. (only available during the IPO sale).
                    </p>
                </div>
                <div className="route__landingPage__sectionSix__bottom__right">
                    <div className="route__landingPage__sectionSix__bottom__right__package">
                        <div className="route__landingPage__sectionSix__bottom__right__package__left">
                            <h1>Bronze investor</h1>
                            <span>
                                <img src="images/landing_tick.svg" alt="" />
                                <p>Early access to new Obscale <br></br> products</p>
                            </span>
                            <span>
                                <img src="images/landing_tick.svg" alt="" />
                                <p>Dividends on quarter basis</p>
                            </span>
                            <span>
                                <img src="images/landing_tick.svg" alt="" />
                                <p>Occasional free samples at our exhibitions stands</p>
                            </span>
                            <button onClick={() => heroRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })}>BUY NOW</button>
                        </div>
                        <div className="route__landingPage__sectionSix__bottom__right__package__right">
                            <img className="route__landingPage__sectionSix__bottom__right__package__right__img" src="images/landing_agentPackage.png" alt="" />
                            <div className="route__landingPage__sectionSix__bottom__right__package__right__wrapper">
                                <p className="route__landingPage__sectionSix__bottom__right__package__right__wrapper__smallP">When you spend</p>
                                <p className="route__landingPage__sectionSix__bottom__right__package__right__wrapper__bigP">$100 or more</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="route__landingPage__sectionSeven">
            <p className="route__landingPage__sectionSeven__smallP">PRICES</p>
            <h1 className="route__landingPage__sectionSeven__h1">Packages</h1>
            <p className="route__landingPage__sectionSeven__p">
                Buy OBSCALE Token now and get exclusive rewards. The more you buy, the more you get. These benefits will never be available again.
            </p>
            <div className="route__landingPage__sectionSeven__wrapper">
                <div className="route__landingPage__sectionSeven__wrapper__item">
                    <div className="route__landingPage__sectionSeven__wrapper__item__left">
                        <h1>Bronze investor</h1>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>Early access to new Obscale <br></br> products</p>
                        </span>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>Dividends on quarter basis</p>
                        </span>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>Occasional free samples at our exhibitions stands</p>
                        </span>
                        <button onClick={() => heroRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })}>BUY NOW</button>
                    </div>
                    <div className="route__landingPage__sectionSeven__wrapper__item__right">
                        <img className="route__landingPage__sectionSeven__wrapper__item__right__img" src="images/landing_agentPackage.png" alt="" />
                        <div className="route__landingPage__sectionSeven__wrapper__item__right__wrapper">
                            <p className="route__landingPage__sectionSeven__wrapper__item__right__wrapper__smallP">When you spend</p>
                            <p className="route__landingPage__sectionSeven__wrapper__item__right__wrapper__bigP">$100 or more </p>
                        </div>
                    </div>
                </div>
                <div className="route__landingPage__sectionSeven__wrapper__item">
                    <div className="route__landingPage__sectionSeven__wrapper__item__left">
                        <h1>Silver Investor</h1>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>Early access to new Obscale <br></br> products</p>
                        </span>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>Dividends on quarter basis</p>
                        </span>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>Free new products samples <br></br> delivered to your address when <br></br> issued</p>
                        </span>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>35EUR Voucher for products by <br></br> your choice</p>
                        </span>
                        <button onClick={() => heroRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })}>BUY NOW</button>
                    </div>
                    <div className="route__landingPage__sectionSeven__wrapper__item__right">
                        <img className="route__landingPage__sectionSeven__wrapper__item__right__img small margin-top" src="images/landing_managerPackage.png" alt="" />
                        <div className="route__landingPage__sectionSeven__wrapper__item__right__wrapper">
                            <p className="route__landingPage__sectionSeven__wrapper__item__right__wrapper__smallP">When you spend</p>
                            <p className="route__landingPage__sectionSeven__wrapper__item__right__wrapper__bigP">$1,000 - $9,999</p>
                        </div>
                    </div>
                </div>
                <div className="route__landingPage__sectionSeven__wrapper__item lastPackage">
                    <div className="route__landingPage__sectionSeven__wrapper__item__left">
                        <h1>Gold Investor</h1>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>Early access to new Obscale <br></br> products</p>
                        </span>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>Dividends on quarter basis</p>
                        </span>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>Occasional free samples at our exhibitions stands</p>
                        </span>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>Free new products samples <br></br> delivered to your address when <br></br> issued</p>
                        </span>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>150 EUR Voucher for products by <br></br> your choice</p>
                        </span>
                        <span>
                            <img src="images/landing_tick.svg" alt="" />
                            <p>2 day all inclusive free trip to <br></br> visit our companies production.</p>
                        </span>
                        <button onClick={() => heroRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })}>BUY NOW</button>
                    </div>
                    <div className="route__landingPage__sectionSeven__wrapper__item__right">
                        <img className="route__landingPage__sectionSeven__wrapper__item__right__img small" src="images/landing_bossPackage.png" alt="" />
                        <div className="route__landingPage__sectionSeven__wrapper__item__right__wrapper">
                            <p className="route__landingPage__sectionSeven__wrapper__item__right__wrapper__smallP">When you spend</p>
                            <p className="route__landingPage__sectionSeven__wrapper__item__right__wrapper__bigP">$10,000 - $24,999</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="route__landingPage__sectionEight">
            <h1 className="route__landingPage__sectionEight__h1">It’s Never Been Easier to Invest in <span>OBSCALE</span> Token</h1>
            <p className="route__landingPage__sectionEight__p">With Obscale’s blockchain based solution you can invest in income-generating company within seconds from anywhere in the world with as little as $100 and still get all the advantages of being a real estate investor.</p>
            <div className="route__landingPage__sectionEight__table">
                <strong className="route__landingPage__sectionEight__table__left" style={{ borderTop: '1px solid #E5E7EB' }}>Features</strong>
                <strong className="route__landingPage__sectionEight__table__centered" style={{ fontWeight: '600', borderTop: '1px solid #E5E7EB' }}><span>OBSCALE</span> TOKEN</strong>
                <strong className="route__landingPage__sectionEight__table__centered" style={{ borderTop: '1px solid #E5E7EB' }}>Traditional Token investment</strong>
                <strong className="route__landingPage__sectionEight__table__left">Get started with as little as $100</strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_tickBlue.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_xRed.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__left">Invest in Obscale Token worldwide</strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_tickBlue.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_xRed.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__left">Hedge Against Inflation</strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_tickBlue.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_tickBlue.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__left">No Hidden Fees</strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_tickBlue.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_xRed.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__left">No Paperwork</strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_tickBlue.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_xRed.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__left">Long term revenue income</strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_tickBlue.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__centered"><img src="images/landing_xRed.svg" alt="" /></strong>
                <strong className="route__landingPage__sectionEight__table__left">Time to buy & Sell a Token on average</strong>
                <strong className="route__landingPage__sectionEight__table__centered">20 seconds</strong>
                <strong className="route__landingPage__sectionEight__table__centered">60 days</strong>
            </div>
        </div>

        <div className="route__landingPage__sectionEleven">
            <div className="route__landingPage__sectionEleven__left">
                <h1 className="route__landingPage__sectionEleven__left__h1">OBSCALE TOKEN <span>SALE</span></h1>
                <p className="route__landingPage__sectionEleven__left__p">
                    During token sale, the prices of the token climbs up every <br></br> month. So make sure to grab it before it’s too hot!
                    <br /><br />
                    Profit sharing will be made according the sum of OBC tokens in you wallet. On specific date Obscale company will make snapshot of wallet addresses containing OBC tokens and those wallets are entitled  to receive the next dividend distribution.
                    <br /><br />
                    The first dividend distribution will be made in end of January 2024. After first distribution there will be distribution every quarter. The dividend payment will be in USDT on wallet containing OBC tokens.
                </p>
                <p className="route__landingPage__sectionEleven__left__p">Right now 1 Obscale Token = $0.0037</p>
            </div>
            <div className="route__landingPage__sectionEleven__right">
                <img src="images/landing_chart.svg" alt="" />
            </div>
        </div>

        <div className="route__landingPage__sectionTwelve">
            <div className="route__landingPage__sectionTwelve__left">
                <img src="images/landing_tablet.svg" alt="" />
            </div>
            <div className="route__landingPage__sectionTwelve__right">
                <h1 className="route__landingPage__sectionTwelve__right__h1">
                    A Secure Platform with <br></br> Profit-Sharing Opportunities
                </h1>
                <p className="route__landingPage__sectionTwelve__right__p">
                    The Obscale platform operates on a profit-sharing model, where 25% of the company's profits are distributed among token holders. This means that investors can not only benefit from the growth potential of the platform but also earn a share of the profits generated by the company.
                    <br></br><br></br>
                    Overall, the Obscale token offers a unique investment opportunity for those seeking a secure and profitable venture in the decentralized blockchain space.
                </p>
            </div>
        </div>

        <div className="route__landingPage__sectionEleven">
            <div className="route__landingPage__sectionEleven__left">
                <h1 className="route__landingPage__sectionEleven__left__h1">Read the Obscale Token <span>Whitepaper</span></h1>
                <p className="route__landingPage__sectionEleven__left__p">
                    The Obscale Token white paper is a detailed document that explains the concepts behind the Obscale security Token, a new token that aims to provide a more secure, transparent, and decentralized way for shareholders experience. This white paper is intended to provide interested parties with a comprehensive understanding of the project and its potential impact on the market. It covers a range of topics, from the technical specifications of the token to the team's vision for the project's future. By reading this white paper, users can gain a deeper understanding of the Obscale Token and its potential.
                </p>
                <button className="route__landingPage__sectionFive__end__button" onClick={() => window.open("/whitepaper.pdf", "_blank")}>Read the whitepaper</button>
            </div>
            <div className="route__landingPage__sectionEleven__right">
                <img src="images/landing_whitepaper.png" alt="" />
            </div>
        </div>

        <div className="route__landingPage__sectionThirteen">
            <h1 className="route__landingPage__sectionThirteen__h1">Meet Our Departments</h1>
            <p className="route__landingPage__sectionThirteen__p">Our company is comprised of a talented group of professionals who are passionate about delivering high-quality products and services to our clients. We believe in working collaboratively to achieve our goals and are committed to providing exceptional customer service.</p>
            <div className="route__landingPage__sectionThirteen__wrapper">
                <div className="route__landingPage__sectionThirteen__wrapper__item">
                    <div className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap">
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                        >
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/development/d1.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/development/d2.jpg" alt="" /></SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="route__landingPage__sectionThirteen__wrapper__item__right">
                        <div className="route__landingPage__sectionThirteen__wrapper__item__right__background"></div>
                        <h1 className="route__landingPage__sectionThirteen__wrapper__item__right__h1">Development team</h1>
                        <p className="route__landingPage__sectionThirteen__wrapper__item__right__p">
                            Our development team consists of skilled programmers and engineers who work tirelessly to create innovative solutions for our clients. They are experienced in a range of programming languages and are dedicated to staying up-to-date with the latest technological advancements.
                        </p>
                    </div>
                </div>
                <div className="route__landingPage__sectionThirteen__wrapper__item">
                    <div className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap">
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                        >
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/marketing/ma1.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/marketing/ma2.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/marketing/ma3.jpg" alt="" /></SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="route__landingPage__sectionThirteen__wrapper__item__right">
                        <div className="route__landingPage__sectionThirteen__wrapper__item__right__background"></div>
                        <h1 className="route__landingPage__sectionThirteen__wrapper__item__right__h1">Marketing team</h1>
                        <p className="route__landingPage__sectionThirteen__wrapper__item__right__p">
                            Our marketing team is made up of creative and strategic thinkers who are responsible for promoting our products and services to potential customers. They work to develop effective marketing campaigns and build strong relationships with our clients.
                        </p>
                    </div>
                </div>
                <div className="route__landingPage__sectionThirteen__wrapper__item">
                    <div className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap">
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                        >
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/manufacturing/m1.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/manufacturing/m2.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/manufacturing/m3.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/manufacturing/m4.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/manufacturing/m5.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/manufacturing/m6.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/manufacturing/m7.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/manufacturing/m8.jpg" alt="" /></SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="route__landingPage__sectionThirteen__wrapper__item__right">
                        <div className="route__landingPage__sectionThirteen__wrapper__item__right__background"></div>
                        <h1 className="route__landingPage__sectionThirteen__wrapper__item__right__h1">Manufacturing team</h1>
                        <p className="route__landingPage__sectionThirteen__wrapper__item__right__p">
                            Our pharmaceutical team is comprised of experienced scientists and researchers who are committed to advancing medical science and developing new treatments for various diseases. They work in state-of-the-art laboratories and collaborate with leading researchers from around the world.
                        </p>
                    </div>
                </div>
                <div className="route__landingPage__sectionThirteen__wrapper__item">
                    <div className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap">
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                        >
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/accounting/a1.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/accounting/a2.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img className="route__landingPage__sectionThirteen__wrapper__item__sliderWrap__img" src="images/sliders/accounting/a3.jpg" alt="" /></SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="route__landingPage__sectionThirteen__wrapper__item__right">
                        <div className="route__landingPage__sectionThirteen__wrapper__item__right__background"></div>
                        <h1 className="route__landingPage__sectionThirteen__wrapper__item__right__h1">Accounting team</h1>
                        <p className="route__landingPage__sectionThirteen__wrapper__item__right__p">
                            Our accounting team consists of highly skilled professionals who are responsible for managing our financial operations. They are experienced in financial reporting, tax compliance, and auditing, and work closely with our executive team to ensure our financial goals are met.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div className="route__landingPage__sectionNine">
            <h1 className="route__landingPage__sectionNine__h1">Meet Our Team</h1>
            <p className="route__landingPage__sectionNine__p">
                Our team is based in Bosnia ang Hercegovina & Slovenia. We have several years of experience running successful international businesses, companies with a primary focus on scaling innovative start-ups in different industries.
            </p>
            <div className="route__landingPage__sectionNine__wrapper">
                <div className="route__landingPage__sectionNine__wrapper__person">
                    <img className="route__landingPage__sectionNine__wrapper__person__image" src="images/landing_esef.png" alt="" />
                    <h2 className="route__landingPage__sectionNine__wrapper__person__name">Ešef Ćosić</h2>
                    <p className="route__landingPage__sectionNine__wrapper__person__func">CEO, CO-FOUNDER</p>
                    <p className="route__landingPage__sectionNine__wrapper__person__desc">Head of development team</p>
                </div>
                <div className="route__landingPage__sectionNine__wrapper__person">
                    <img className="route__landingPage__sectionNine__wrapper__person__image" src="images/landing_senad.png" alt="" />
                    <h2 className="route__landingPage__sectionNine__wrapper__person__name">Senad Bećirović</h2>
                    <p className="route__landingPage__sectionNine__wrapper__person__func">CEO, CO-FOUNDER</p>
                    <p className="route__landingPage__sectionNine__wrapper__person__desc">Head of marketing team</p>
                </div>
                <div className="route__landingPage__sectionNine__wrapper__person">
                    <img className="route__landingPage__sectionNine__wrapper__person__image" src="images/landing_robert.png" alt="" />
                    <h2 className="route__landingPage__sectionNine__wrapper__person__name">Robert Rupnik</h2>
                    <p className="route__landingPage__sectionNine__wrapper__person__func">External Advisory</p>
                </div>
                <div className="route__landingPage__sectionNine__wrapper__person">
                    <img className="route__landingPage__sectionNine__wrapper__person__image" src="images/landing_emilija.png" alt="" />
                    <h2 className="route__landingPage__sectionNine__wrapper__person__name" >Emilija Spaseska <br></br> Aleksovska</h2>
                    <p className="route__landingPage__sectionNine__wrapper__person__func" >Master of Pharmacy</p>
                </div>
                <div className="route__landingPage__sectionNine__wrapper__person">
                    <img className="route__landingPage__sectionNine__wrapper__person__image" src="images/landing_midhat.png" alt="" />
                    <h2 className="route__landingPage__sectionNine__wrapper__person__name">Midhat Jašić</h2>
                    <p className="route__landingPage__sectionNine__wrapper__person__func">Doctor, professor of nutrition</p>
                </div>
                <div className="route__landingPage__sectionNine__wrapper__person">
                    <img className="route__landingPage__sectionNine__wrapper__person__image" src="images/landing_hanifa.png" alt="" />
                    <h2 className="route__landingPage__sectionNine__wrapper__person__name">Hanifa Mujić</h2>
                    <p className="route__landingPage__sectionNine__wrapper__person__func">Engineer of chemical <br></br> technology</p>
                </div>
            </div>
        </div>

        <div className="route__landingPage__sectionTen">
            <h1 className="route__landingPage__sectionTen__h1">Partnerships</h1>
            <div className="route__landingPage__sectionTen__wrapper">
                <img onClick={() => window.open('https://propellerads.com', '_blank')} src="images/landing_propellerads.svg" alt="" />
                <img onClick={() => window.open('https://zeropark.com', '_blank')} src="images/landing_zeropark.svg" alt="" />
                <img onClick={() => window.open('https://adscompass.com', '_blank')} src="images/landing_adscompass.svg" alt="" />
                <img onClick={() => window.open('https://adcash.com', '_blank')} src="images/landing_adcash.svg" alt="" />
                <img onClick={() => window.open('https://adnow.com', '_blank')} src="images/landing_adnow.svg" alt="" />
                <img onClick={() => window.open('https://google.com', '_blank')} src="images/landing_google.png" alt="" />
                <img onClick={() => window.open('https://facebook.com', '_blank')} src="images/landing_fb.svg" alt="" />
                <img onClick={() => window.open('https://mgid.com', '_blank')} src="images/landing_mgid.svg" alt="" />
            </div>
        </div>

        <div className="route__landingPage__sectionTwo">
            <p className="route__landingPage__sectionTwo__smallP">HELP</p>
            <h1 className="route__landingPage__sectionTwo__h1">Questions? We’re here for you</h1>
            <div className="route__landingPage__sectionTwo__wrapper">
                <div className="route__landingPage__sectionTwo__wrapper__block lastSection">
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">What is a IPO?</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        During a IPO (initial public offering) , a limited number of tokens are sold in four stages...
                    </p>
                    <p
                        className="route__landingPage__sectionTwo__wrapper__block__smallP"
                        onClick={(e) => { animateBox(e, <ReadMoreModal content='During a IPO (initial public offering) , a limited number of tokens are sold in four stages at the different price point entry to people who want to get involved early. This is a great chance for people to be among first investors and benefits from lower price entry. Tokens are limited in quantity and are available on basis first come first served' />) }}
                    >
                        Read more
                    </p>
                </div>
                <div className="route__landingPage__sectionTwo__wrapper__divider"></div>
                <div className="route__landingPage__sectionTwo__wrapper__block lastSection">
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">What is OBSCALE TOKEN</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        OBSCALE Token is issued for the purposes of crowdfunding for expansion to two markets Poland and Italy. The token is classified as security token...
                    </p>
                    <p
                        className="route__landingPage__sectionTwo__wrapper__block__smallP"
                        onClick={(e) => { animateBox(e, <ReadMoreModal content='OBSCALE Token is issued for the purposes of crowdfunding for expansion to two markets Poland and Italy. The token is classified as security token . All issued tokens represent 25% of our companies profits that will be shared on quarterly basis.' />) }}
                    >
                        Read more
                    </p>
                </div>
                <div className="route__landingPage__sectionTwo__wrapper__divider"></div>
                <div className="route__landingPage__sectionTwo__wrapper__block lastSection">
                    <h1 className="route__landingPage__sectionTwo__wrapper__block__h1">Why are we doing a IPO?</h1>
                    <p className="route__landingPage__sectionTwo__wrapper__block__p">
                        Your contribution will help us start two new markets of Poland and Italy. The funds will be invested to support our go-to-market...
                    </p>
                    <p
                        className="route__landingPage__sectionTwo__wrapper__block__smallP"
                        onClick={(e) => { animateBox(e, <ReadMoreModal content='Your contribution will help us start two new markets of Poland and Italy. The funds will be invested to support our go-to-market strategy and secure all necesary inventory for start of markets sales. The Obscale token will be listed later on listed in one exchange where investor can trade they tokens. Participate in the Obscale IPO and become a part of our future. Not only will you get early access to our products, but you’ll also receive some benefits for contributions over $100.Don’t miss this opportunity to make a difference and help us create a world where anyone, anywhere, can be shareholder in our profit shareing system.. Join the Obscale community and invest in the future of pharm scale products!' />) }}
                    >
                        Read more
                    </p>
                </div>
            </div>
        </div>

        <Footer />
    </div>
};

const ThankYou = (props) => {
    const handleDashboard = () => {
        props.onClose();
        props.navigateFn("/login");
    };

    return <div className="route__landingPage__thankYou">
        <div className="route__landingPage__thankYou__wrap">
            <div className="route__landingPage__thankYou__wrap__btns">
                <img src="/images/modal_close.svg" onClick={props.onClose} />
            </div>

            <div className="route__landingPage__thankYou__wrap__logo">
                <img src="/images/logo_coin.svg" />
            </div>

            <div className="route__landingPage__thankYou__wrap__text">
                <h1>Thank You</h1>
                <h3>for investing in Obscale Token</h3>
            </div>

            <p className="route__landingPage__thankYou__wrap__subText">
                We appreciate Your trust and support. Your investment will help us grow and provide better services to you.
            </p>

            <div className="route__landingPage__thankYou__wrap__cta" onClick={handleDashboard}>
                Go to dashboard
            </div>
        </div>
    </div>
};

const ReadMoreModal = (props) => {
    return <div className="route__landingPage__readMoreModal">
        <div className="route__landingPage__readMoreModal__wrapper">
            <p>
                {props.content}
            </p>
            <button className="route__landingPage__readMoreModal__wrapper__button" onClick={props.onClose}>Okay</button>
        </div>
    </div>
}

const WarningMessageModal = (props) => {
    return <div className="route__landingPage__warningMessageModal">
        <div className="route__landingPage__warningMessageModal__wrapper">
            <p className="route__landingPage__warningMessageModal__wrapper__message">{props.message}</p>
            <button className="route__landingPage__warningMessageModal__wrapper__button" onClick={props.onClose}>Understand</button>
        </div>
    </div>
}

export default LandingPage;
import React from 'react';
import "./index.scss";

import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import { animateBox } from "../../modules/componentAnimation";

export default function Footer() {
    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    return (
        <div className='component__footer'>
            <p className='component__footer__p'>
                RISK WARNING: Obscale LTD operates the website ipo.obscale.com (“Platform”). By using the Platform, you accept our Terms of Service, Privacy Policy, Cookie Policy and Obscale Token sale agreement. Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may go down as well as the up. Profits may be subject to capital gains or other taxes applicable in your jurisdiction. Nothing on this website should be construed as an offer or recommendation to buy, sell or invest. You are solely responsible for determining whether any purchase or related transaction is appropriate for you based on your personal objectives, financial situation and risk tolerance. You should consult with trusted, licensed legal professionals and advisor for any legal, tax, insurance or business advice. Obscale does not guarantee any performance, outcome or return of capital . By accessing this site and any pages thereof, you agree to be bound by the Obscale sales agreement. Buying cryptocurrencies involve risk and may result in partial or total loss
            </p>
            <div className='component__footer__content'>
                <p onClick={() => animateNavigate('/termsofservice')}>Terms of Service</p>
                <p onClick={() => animateNavigate('/privacypolicy')}>Privacy Policy</p>
                <p onClick={(e) => animateBox(e, <ContactUsModal />)}>Contact Us</p>
                <p onClick={() => animateNavigate('/tokensaleagreement')}>Token Sale Agreement</p>
            </div>
            <p className='component__footer__copy'>Copyright 2023. All rights reserved.</p>
            <div className='component__footer__bottom'>
                <div className='component__footer__bottom__left'>
                    <p onClick={() => animateNavigate('/legalnotice')}>Legal notice</p>
                    <p onClick={() => animateNavigate('/privacypolicy')}>Privacy policy</p>
                    <p onClick={() => animateNavigate('/termsofservice')}>Terms & conditions</p>
                </div>
                <div className='component__footer__bottom__right'>
                    <div>
                        <img onClick={() => window.open('https://www.facebook.com/obscale.doo', '_blank')} src='images/landing_facebook.svg' alt='' />
                        <img onClick={() => window.open('https://www.linkedin.com/company/obscale/mycompany/', '_blank')} src='images/landing_linkedinFooter.svg' alt='' />
                        <img onClick={() => window.open('https://www.instagram.com/obscale_group/', '_blank')} src='images/landing_instagram.svg' alt='' />
                    </div>
                    <p>&copy; 2023 Obscale</p>
                </div>
            </div>
        </div>
    )
}

const ContactUsModal = (props) => {
    return <div className='component__contactUs'>
        <div className='component__contactUs__wrapper'>
            <img src='images/landing_x.svg' alt='' onClick={props.onClose} />
            <p>To get in touch with us, send us an email at: <span>info@obscale.com</span></p>
        </div>
    </div>
}
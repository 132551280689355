import React from "react";

import ConnectWallet from "../ConnectWallet";
import "./index.scss";

import { useNavigate, useLocation } from "react-router-dom";
import animateModule from "../../modules/animateModule";

const Header = () => {
    const [mobileNav, setMobileNav] = React.useState(false);

    const curNavigate = useNavigate();
    let curLocation = useLocation();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const navigateHandler = (elem, mobileHandler, index) => {
        if (curLocation.pathname === '/') {
            if(index){
                document.querySelectorAll(elem)[index].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            }
            else{
                document.querySelector(elem).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            }
            mobileHandler(n => !n);
        }
        else {
            animateNavigate('/').then(() => {
                if(index){
                    document.querySelectorAll(elem)[index].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
                else{
                    document.querySelector(elem).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
            });
        }
    }

    return <div className="component__header">
        <img onClick={() => animateNavigate('/')} className="component__header__h1" src="images/header_logo.svg" alt="" />
        <div className={`component__header__links ${mobileNav ? "component__header__links--active" : ''}`}>
            <p className="component__header__links__link" onClick={() => window.open("/whitepaper.pdf", "_blank")}>Whitepaper</p>
            <p className="component__header__links__link" onClick={() => navigateHandler('.route__landingPage__sectionTwo', setMobileNav)}>Invest</p>
            <p className="component__header__links__link" onClick={() => navigateHandler('.route__landingPage__sectionThree', setMobileNav)}>What is Obscale</p>
            <p className="component__header__links__link" onClick={() => navigateHandler('.route__landingPage__sectionSix__wrapper', setMobileNav)}>How to Buy</p>
            <p className="component__header__links__link" onClick={() => navigateHandler('.route__landingPage__sectionNine', setMobileNav)}>Team</p>
            <p className="component__header__links__link" onClick={() => navigateHandler('.route__landingPage__sectionTwo', setMobileNav, 2)}>FAQ</p>
        </div>
        <ConnectWallet />
        <img className="component__header__hamburger" src="images/header_hamburger.svg" alt="" onClick={() => setMobileNav(n => !n)} />
    </div>
};

export default Header;
import React from 'react';
import "./index.scss";

import Footer from "../../components/Footer";

function TermsOfService() {
    const topRef = React.useRef(null);

    React.useEffect(() => {
      if(topRef.current) topRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, []);

    return (
        <>
            <div className='route__terms'>
                <h1 ref={topRef}>Obscale token Terms of Service</h1>
                <hr />
                <h2>1. Introduction</h2>
                <p>
                    These terms of service ("Terms") govern your use of Obscale token, a
                    cryptocurrency operated by Obscale token, Inc. ("Obscale token," "we,"
                    "us," or "our"). By using Obscale token, you agree to be bound by these
                    Terms.
                </p>
                <hr />
                <h2>2. User Eligibility and Registration</h2>
                <p>
                    2.1 User Eligibility. By using Obscale token, you represent and warrant
                    that you are at least 18 years of age and have the legal capacity to
                    enter into these Terms.
                </p>
                <p>
                    2.2 Registration. To use certain features of Obscale token, you may be
                    required to register with us and provide certain information about
                    yourself. You agree to provide accurate and complete information when
                    registering for an account and to keep your account information
                    up-to-date.
                </p>
                <hr />
                <h2>3. Ownership and Intellectual Property</h2>
                <p>
                    3.1 Ownership. Obscale token, including its website and all content and
                    software relating to the token, is owned by Obscale token and its
                    licensors and is protected by United States and international copyright
                    and other intellectual property laws.
                </p>
                <p>
                    3.2 Trademarks. "Obscale token" and all related logos are trademarks of
                    Obscale token. You may not use these trademarks without our prior written
                    permission.
                </p>
                <hr />
                <h2>4. Limitation of Liability</h2>
                <p>
                    4.1 Disclaimer of Warranties. The token is provided "as is" and without
                    warranty of any kind, whether express, implied, or statutory, including
                    but not limited to any warranty of merchantability, non-infringement, or
                    fitness for a particular purpose.
                </p>
                <p>
                    4.2 Limitation of Liability. Obscale token shall not be liable for any
                    indirect, incidental, special, consequential, or punitive damages, or
                    any loss of profits or revenues, whether incurred directly or
                    indirectly, or any loss of data, use, goodwill, or other intangible
                    losses resulting from (a) your access to or use of or inability to
                    access or use the token; (b) any conduct or content of any third party on
                    the token, including without limitation, any defamatory, offensive or
                    illegal conduct of other users or third parties; or (c) unauthorized
                    access, use or alteration of your transmissions or content. This
                    limitation of liability shall apply to the fullest extent permitted by
                    law and shall survive any termination or expiration of your use of the
                    token, whether or not such damages are foreseeable and whether or not
                    Obscale token has been advised of the possibility of such damages. In no
                    event shall Obscale token's total liability to you for all damages,
                    losses, and causes of action arising out of or in connection with these
                    Terms or your use of the token exceed the amount paid by you, if any, for accessing the token during the twelve months preceding the date on which the claim arose.
                </p>
                <hr />
                <h2>5. Indemnification</h2>
                <p>
                    You agree to indemnify, defend, and hold harmless Obscale token and its
                    officers, directors, employees, agents, and affiliates from and against
                    any and all claims, liabilities, damages, losses, and expenses,
                    including reasonable attorneys' fees and costs, arising out of or in any
                    way connected with (a) your access to or use of the token; (b) your
                    violation of these Terms; (c) your violation of any third-party right,
                    including without limitation any intellectual property or privacy
                    right; or (d) any claim that your use of the token caused damage to a
                    third party. We reserve the right to assume the exclusive defense and
                    control of any matter otherwise subject to indemnification by you, in
                    which event you will assist and cooperate with us in asserting any
                    available defenses.
                </p>
                <hr />
                <h2>6. Termination</h2>
                <p>
                    Obscale token may terminate or suspend your access to all or any part of
                    the token at any time, with or without cause, with or without notice,
                    effective immediately. Upon termination of these Terms or your access to
                    the token for any reason, all provisions of these Terms which by their
                    nature should survive termination shall survive termination, including,
                    without limitation, ownership provisions, warranty disclaimers,
                    indemnity, and limitations of liability.
                </p>
                <hr />
                <h2>7. Governing Law and Dispute Resolution</h2>
                <p>
                    These Terms shall be governed by and construed in accordance with the
                    laws of the State of California, without regard to its conflict of law
                    provisions. Any dispute arising out of or relating to these Terms or the
                    token shall be resolved exclusively through binding arbitration in San
                    Francisco, California, in accordance with the rules of the American
                    Arbitration Association. The arbitration shall be conducted by a single
                    arbitrator, and judgment on the arbitration award may be entered in any
                    court having jurisdiction thereof. Notwithstanding the foregoing, we
                    may seek injunctive or other equitable relief to protect our
                    intellectual property rights in any court of competent jurisdiction.
                </p>
                <hr />
                <h2>8. Changes to These Terms</h2>
                <p>
                    Obscale token reserves the right to modify or update these Terms from
                    time to time in its sole discretion. Your continued use of the token
                    following the posting of any changes to these Terms constitutes your
                    acceptance of such changes. It is your responsibility to check these
                    Terms periodically for changes.
                </p>
            </div>
            <Footer />
        </>
    );
}

export default TermsOfService;
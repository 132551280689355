import React from 'react';
import "./index.scss";

import Footer from "../../components/Footer";

const LegalNotice = () => {
  const topRef = React.useRef(null);

  React.useEffect(() => {
    if(topRef.current) topRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }, []);

  return (
    <>
      <div className='route__legalNotice'>
        <h1 ref={topRef}>LEGAL NOTICE</h1>
        <p>This legal notice applies to the use of the Obscale token, hereafter referred to as "the token". By using the token, you agree to the terms and conditions outlined in this legal notice.</p>

        <h2>1. Introduction</h2>
        <p>The Obscale token is a digital asset that enables holders to access certain services and features of the Obscale platform. The token is not intended to be a security or investment and should not be treated as such.</p>

        <h2>2. Token Ownership and Transfer</h2>
        <p>Ownership of the Obscale token is tracked on the Ethereum blockchain. Tokens may be transferred freely, subject to any applicable laws and regulations.</p>

        <h2>3. Risks and Limitations</h2>
        <p>Use of the Obscale token involves certain risks, including but not limited to market and liquidity risk, regulatory risk, and technology risk. The Obscale team is not responsible for any losses or damages resulting from the use of the token.</p>

        <h2>4. Disclaimer of Warranties</h2>
        <p>The Obscale token is provided on an "as is" and "as available" basis. The Obscale team makes no representations or warranties of any kind, express or implied, as to the token's utility, security, or performance.</p>

        <h2>5. Limitation of Liability</h2>
        <p>The Obscale team will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of the Obscale token or any services or products related to the token.</p>

        <h2>6. Governing Law and Jurisdiction</h2>
        <p>This legal notice shall be governed by and construed in accordance with the laws of [insert your jurisdiction]. Any disputes arising out of or in connection with this legal notice shall be subject to the exclusive jurisdiction of the courts of [insert your jurisdiction].</p>
      </div>
      <Footer />
    </>
  );
};

export default LegalNotice;
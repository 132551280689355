import React from "react";
import "./index.scss";
import axios from "axios";

import Sidebar from "../../components/Sidebar";
import Dashboard from "./Dashboard";
import Referrals from "./Referrals";
import ReferralsAdmin from "./ReferralsAdmin";
import SettingsAdmin from "./SettingsAdmin";

import { Routes, Route } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";

import Spinner from "../../components/customComponents/Spinner";

const DashboardLanding = () => {
    const [userData, setUserData] = React.useState();

    const mainNavigate = useNavigate();
    const mainLocation = useLocation();

    const animateNavigate = to => animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));

    const checkLogin = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/auth/checkLogin`,
            ...backendModule.axiosConfig,
        }).then((res) => {
            if (res.data.status === 'ok') {
                setUserData(res.data.data);
            }
            else {
                animateNavigate('/login');
            }
        }).catch(() => {
            animateNavigate('/login');
        });
    }

    React.useEffect(() => {
        let header = document.querySelector(".component__header");
        if (header) header.style.display = "none";

        return () => header.style.display = null;
    }, [mainLocation.pathname]);

    React.useEffect(() => {
        checkLogin();
    }, [mainLocation.pathname]);

    if (!userData) return <div className="route__login"><Spinner style={{ width: "64px", height: "64px" }} color="#5956E9" align="center" /></div>

    return <div className="route__dashboardLanding">
        <Sidebar userData={userData} />
        <div className="route__dashboardLanding__wrap">
            <Routes>
                <Route path='/' element={<Dashboard userData={userData} />} />
                <Route path='/referrals' element={<Referrals userData={userData} />} />
                <Route path='/adminreferrals' element={<ReferralsAdmin />} />
                <Route path='/adminsettings' element={<SettingsAdmin />} />
            </Routes>
        </div>
    </div>

}
export default DashboardLanding;
import React from 'react';
import "./index.scss";

import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  const topRef = React.useRef(null);

  React.useEffect(() => {
    if (topRef.current) topRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }, []);

  return (
    <>
      <div className='route__privacyPolicy'>
        <h1 ref={topRef}>Privacy Policy for Obscale token</h1>

        <p>At Obscale token, accessible from obscale.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Obscale token and how we use it.</p>

        <h2>Information We Collect</h2>

        <p>We collect several types of information from and about users of our Services, including:</p>

        <ul>
          <li>Personal information, such as name and email address, that you provide to us when you register for an account or sign up for our newsletter</li>
          <li>Transaction information, such as the amount and recipient of any transactions you make using our Services</li>
          <li>Technical information, such as IP address and browser type, that is automatically collected when you use our Services</li>
        </ul>

        <h2>How We Use Your Information</h2>

        <p>We may use the information we collect from you to:</p>

        <ul>
          <li>Provide and maintain our Services</li>
          <li>Process transactions</li>
          <li>Send you information about our Services or other products and services that we believe may interest you</li>
          <li>Respond to your inquiries or requests</li>
          <li>Improve our Services and customize your experience</li>
        </ul>

        <h2>Sharing Your Information</h2>

        <p>We may share your information with third-party service providers that perform services on our behalf, such as payment processing and customer support. We may also share your information with law enforcement or other third parties if we believe in good faith that disclosure is necessary to comply with applicable laws or regulations, to respond to a subpoena, court order, or other legal process, or to protect the rights, property, or safety of Obscale token, our users, or others.</p>

        <h2>Your Rights</h2>

        <p>You have certain rights with respect to your personal information, including the right to access, correct, or delete your information, as well as the right to object to or restrict certain processing of your information. If you would like to exercise any of these rights, please contact us using the information below.</p>

        <h2>Updates to this Policy</h2>

        <p>We may update this Privacy Policy from time to time. If we make material changes, we will notify you by email or by posting a notice on our website prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.</p>

        <h2>Contact Us</h2>

        <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at privacy@obscale.com.</p>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
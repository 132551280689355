import React from "react";
import "./index.scss";
import Web3 from "web3";

import axios from "axios";
import { useMetaMask } from "metamask-react";
import { useNavigate } from "react-router-dom";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";

import Spinner from "../../components/customComponents/Spinner";

const Login = () => {
    const [loginPhase, setLoginPhase] = React.useState(0);
    const mainMetaMask = useMetaMask();
    const mainNavigate = useNavigate();

    const web3 = new Web3(window.ethereum);

    const animateNavigate = to => animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));

    React.useEffect(() => {
        let header = document.querySelector(".component__header");
        if (header) header.style.display = "none";

        return () => header.style.display = null;
    }, []);

    React.useEffect(() => {
        if (
            mainMetaMask.status === "initializing" ||
            mainMetaMask.status === "connecting"
        ) return;
        if (loginPhase === 2 && mainMetaMask.status === "connected") return setLoginPhase(0);
        if (loginPhase === 0) {
            if (mainMetaMask.status === "unavailable") return setLoginPhase(1);
            if (mainMetaMask.status === "notConnected") return setLoginPhase(2);
        };

        if (mainMetaMask.status === "connected" && loginPhase === 0) setLoginPhase(3);
    }, [loginPhase, mainMetaMask.status]);

    React.useEffect(() => {
        if (loginPhase === 3) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/auth/checkLogin`,
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    animateNavigate("/dashboard");
                } else {
                    setLoginPhase(4);
                };
            }).catch(() => {
                setLoginPhase(-1);
            });
        };
        if (loginPhase === 4) {
            axios.defaults.headers = {
                "x-wallet": mainMetaMask.account
            };
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/auth/generateNounce`,
                ...backendModule.axiosConfig
            }).then(async res => {
                if (res.data.status === "ok") {
                    let msg = `Sign this nonce to login: ${res.data.data}`;
                    let sig = await web3.eth.personal.sign(msg, mainMetaMask.account).catch(() => null);
                    if (!sig) return setLoginPhase(-1);

                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/auth/login`,
                        data: {
                            Signature: sig
                        },
                        ...backendModule.axiosConfig
                    }).then(res => {
                        if (res.data.status === "ok") {
                            axios.defaults.headers["x-message"] = res.data.data;
                            setLoginPhase(0);
                        } else {
                            setLoginPhase(-1);
                        };
                    }).catch(() => {
                        setLoginPhase(-1);
                    });
                } else {
                    setLoginPhase(-1);
                };
            }).catch(() => {
                setLoginPhase(-1);
            });
        };
    }, [loginPhase]);

    return <div className="route__login">{(()=>{
        if (
            loginPhase === 0 ||
            loginPhase === 3 ||
            loginPhase === 4 ||
            mainMetaMask.status === "connecting" ||
            mainMetaMask.status === "initializing"
        ) return <Spinner style={{width: "64px", height: "64px"}} color="#5956E9" align="center" />
        if (loginPhase === -1) return <>
            <p>There was an error while logging You in!</p>
            <div className="route__login__btn" onClick={() => setLoginPhase(0)}>
                <img src="/images/metamask_logo.svg" />
                <span>Retry</span>
            </div>
        </>
        if (loginPhase === 1) return <div className="route__login__btn" onClick={() => window.open("https://metamask.io", "_blank")}>
            <img src="/images/metamask_logo.svg" />
            <span>Install Metamask</span>
        </div>
        if (loginPhase === 2) return <div className="route__login__btn" onClick={() => mainMetaMask.connect().catch(() => null)}>
            <img src="/images/metamask_logo.svg" />
            <span>Login with Metamask</span>
        </div>
    })()}</div>
};

export default Login;
import React from "react";
import "./index.scss";
import Web3 from "web3";
import { useMetaMask } from "metamask-react";
import Contract from "../../../modules/contract.json";
import { Doughnut } from 'react-chartjs-2';
import Footer from "../../../components/Footer";
import Spinner from "../../../components/customComponents/Spinner";

import * as backendModule from "../../../modules/backendModule";
import axios from "axios";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from "moment/moment";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend
);

const Dashboard = (props) => {
    const [dropdown, setDropdown] = React.useState(false);
    const [chartsData, setChartsData] = React.useState([]);
    const [tokenPrice, setTokenPrice] = React.useState(0);
    const [transactions, setTransactions] = React.useState([]);
    const [selectedOrder, setSelectedOrder] = React.useState({ value: 7, friendlyValue: 7, ext: 'days' });
    const [totalTokens, setTotalTokens] = React.useState(0);
    const [totalUSDT, setTotalUSDT] = React.useState(0);
    const [search, setSearch] = React.useState(null);

    const searchRef = React.useRef(null);

    const getContractInstance = () => {
        const web3 = new Web3(window.ethereum);
        return new web3.eth.Contract(Contract.abi, Contract.address);
    };

    const getData = () => {
        const contract = getContractInstance();

        contract.methods.fixedTokenPrice().call().then(res => {
            setTokenPrice((Number(res) / 1000).toFixed(4));
        });
    };

    const mainMetamask = useMetaMask();

    React.useEffect(() => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/transactions/getTransactionStats`,
            data: {
                Address: props.userData?.Address,
                filters: [
                    { name: 'createdAt', op: 'dgeq', value: moment().add(-7, 'day').toDate().getTime() }
                ]
            },
            ...backendModule.axiosConfig,
        }).then((res) => {
            if (res.data.status === 'ok') {
                setChartsData(res.data);
            }
            else {
                setChartsData('error');
            }
        }).catch(() => {
            setChartsData('error');
        });
    }, []);

    React.useEffect(() => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/transactions/getAllTransactions`,
            data: {
                filters: [
                    { name: 'Address', op: 'eq', value: props.userData?.Address },
                    { name: 'createdAt', op: 'dgeq', value: moment().add(-selectedOrder.value, 'day').toDate().getTime() },
                    search ? { name: 'TransactionHash', op: 'like', value: search } : null
                ]
            },
            ...backendModule.axiosConfig,
        }).then((res) => {
            if (res.data.status === 'ok') {
                setTransactions(res.data);
            }
            else {
                setTransactions(res.data);
            }
        }).catch(() => {
            setTransactions({ status: 'error' });
        });
    }, [selectedOrder, search]);

    React.useEffect(() => {
        if (transactions.status === 'ok') {
            setTotalTokens(transactions?.data?.reduce((acc, val) => Number(acc) + Number(val.OBCAmount), 0));
            setTotalUSDT(transactions?.data?.reduce((acc, val) => Number(acc) + Number(val.USDTAmount), 0));
        }
    }, [transactions]);

    React.useEffect(() => {
        let header = document.querySelector(".component__header");
        if (header) header.style.display = "none";

        return () => header.style.display = null;
    }, []);

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="route__dashboard">
        <div className="route__dashboard__content">
            <div className="route__dashboard__content__notification">
                It might take up to 2 hours for the transactions to be verified.
            </div>

            <div className="route__dashboard__content__header">
                <div className="route__dashboard__content__header__buttons">
                    <div className="route__dashboard__content__header__buttons__single">
                        <p>{String(props.userData.Address).substring(0, 15)}...</p>
                        <img src="/images/dashboard/profile.svg" alt="" />
                    </div>
                </div>
            </div>
            <div className="route__dashboard__content__kpi">
                <div className="route__dashboard__content__kpi__single">
                    <div className="route__dashboard__content__kpi__single__head">
                        <p>Total Obscale Tokens</p>
                        {transactions.status === 'ok' && <p>{totalTokens} OBC</p>}
                        {transactions.status === 'error' && <p>Error occured</p>}
                    </div>
                    <div className="route__dashboard__content__kpi__single__chart">
                        <img alt="" src="/images/dashboard/obc.png" />
                    </div>
                </div>
                <div className="route__dashboard__content__kpi__single">
                    <div className="route__dashboard__content__kpi__single__head">
                        <p>Total cost</p>
                        {transactions.status === 'ok' && <p>{totalUSDT} USDT</p>}
                        {transactions.status === 'error' && <p>Error occured</p>}
                    </div>
                    <div className="route__dashboard__content__kpi__single__chart">
                        <img alt="" src="/images/dashboard/totalCost.png" />
                    </div>
                </div>
                <div className="route__dashboard__content__kpi__single">
                    <div className="route__dashboard__content__kpi__single__head">
                        <p>Total Transactions</p>
                        {transactions.status === 'ok' && <p>{transactions?.data?.length}</p>}
                        {transactions.status === 'error' && <p>Error occured</p>}
                    </div>
                    <div className="route__dashboard__content__kpi__single__chart">
                        <img alt="" src="/images/dashboard/totalTransactions.png" />
                    </div>
                </div>
                {props?.userData?.Referral && <div className="route__dashboard__content__kpi__single">
                    <div className="route__dashboard__content__kpi__single__text">
                        <p>Referral link</p>
                        <p>Use your unique referral link from the dashboard to invite others to buy Obscale tokens and earn rewards</p>
                        <input value={`${backendModule.baseURL}?ref=${props.userData.Referral}`} />
                    </div>
                    <div className="route__dashboard__content__kpi__single__chart">
                        <img alt="" src="/images/dashboard/rocket.png" />
                    </div>
                </div>}
            </div>
            <h1>General overview</h1>
            {chartsData.status === 'ok' && <div className="route__dashboard__content__kpi2">
                <div className="route__dashboard__content__kpi2__single">
                    <p className="route__dashboard__content__kpi2__single__head">
                        Number of transactions
                    </p>
                    <div className="route__dashboard__content__kpi2__single__chart">
                        <Line options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: 'Number of transactions',
                                },
                            }
                        }} data={{
                            labels: chartsData.data?.transaction_date?.map((item) => { return item.Date }),
                            datasets: [{
                                label: '',
                                data: chartsData.data?.transaction_date?.map((item) => { return item.TotalTransactions }),
                                borderColor: ' rgba(96, 88, 234, 0.3)',
                                backgroundColor: 'rgba(92, 117, 205, 0.5)'

                            }]
                        }} />
                    </div>
                </div>
                <div className="route__dashboard__content__kpi2__single">
                    <p className="route__dashboard__content__kpi2__single__head">
                        Total number of tokens
                    </p>
                    <div className="route__dashboard__content__kpi2__single__chart">
                        <Line options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: 'Number of transactions',
                                },
                            }
                        }} data={{
                            labels: chartsData.data?.transaction_amount.map((item) => { return item.Date }),
                            datasets: [{
                                label: '',
                                data: chartsData.data?.transaction_amount?.map((item) => { return item.Tokens }),
                                borderColor: ' rgba(226, 236, 51, 0.3)',
                                backgroundColor: 'rgba(226, 406, 51, 0.5)'

                            }]
                        }} />
                    </div>
                </div>
                <div className="route__dashboard__content__kpi2__single">
                    <p className="route__dashboard__content__kpi2__single__head">
                        Total value of tokens
                    </p>
                    <div className="route__dashboard__content__kpi2__single__chart">
                        <Line options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: 'Number of transactions',
                                },
                            }
                        }} data={{
                            labels: chartsData.data?.transaction_amount?.map((item) => { return item.Date }),
                            datasets: [{
                                label: '',
                                data: chartsData.data?.transaction_amount?.map((item) => { return item.Tokens * tokenPrice }),
                                borderColor: 'rgba(255, 99, 132, 0.3)',
                                backgroundColor: 'rgba(255, 99, 132, 0.5)'

                            }]
                        }} />
                    </div>
                </div>
            </div>}
            {chartsData === 'error' && <p style={{ color: 'red' }}>Can not get charts data...</p>}
            <div className="route__dashboard__content__tableFilters">
                <h1>Transactions</h1>
                <div className="route__dashboard__content__tableFilters__dropdown">
                    <div
                        className="route__dashboard__content__tableFilters__dropdown__head"
                        style={{ borderBottom: dropdown ? 'none' : '', borderRadius: dropdown ? '5px 5px 0 0' : '' }}
                        onClick={() => setDropdown(d => !d)}
                    >
                        <img src="images/dashboard/filter.svg" alt="" />
                        <p className="route__dashboard__content__tableFilters__dropdown__head__selected">{`${selectedOrder.friendlyValue} ${selectedOrder.ext}`}</p>
                        <img src="images/dashboard/arrowDown.svg" alt="" />
                    </div>
                    <div className={`route__dashboard__content__tableFilters__dropdown__body ${dropdown ? "route__dashboard__content__tableFilters__dropdown__body--active" : ""}`}>
                        <p
                            onClick={() => { setSelectedOrder({ value: 7, friendlyValue: 7, ext: 'days' }); setDropdown(false) }}
                            className="route__dashboard__content__tableFilters__dropdown__body__item"
                        >
                            7 days
                        </p>
                        <p
                            onClick={() => { setSelectedOrder({ value: 31, friendlyValue: 1, ext: 'month' }); setDropdown(false) }}
                            className="route__dashboard__content__tableFilters__dropdown__body__item"
                        >
                            1 month
                        </p>
                        <p
                            onClick={() => { setSelectedOrder({ value: 186, friendlyValue: 6, ext: 'month' }); setDropdown(false) }}
                            className="route__dashboard__content__tableFilters__dropdown__body__item"
                        >
                            6 months
                        </p>
                        <p
                            onClick={() => { setSelectedOrder({ value: 365, friendlyValue: 1, ext: 'year' }); setDropdown(false) }}
                            className="route__dashboard__content__tableFilters__dropdown__body__item"
                        >
                            1 year
                        </p>
                    </div>
                </div>
                <div className="route__dashboard__content__tableFilters__search">
                    <img alt="" src="/images/dashboard/searchIcon.png" />
                    <input ref={searchRef} onChange={() => setTimeout(() => { setSearch(searchRef.current.value) }, 400)} placeholder="Tap here to search" />
                </div>
            </div>
            <div className="route__dashboard__content__kpi3">
                <DashboardTable
                    headers={
                        ['Transaction Hash', 'OBC Amount', 'Status', 'Type', 'Date']
                    }
                    content={transactions.status === 'ok' ? transactions.data : transactions?.status === 'error' ? 'error' : 'spinner'}
                />
            </div>
        </div>
    </div>

}
export default Dashboard;


const DashboardTable = (props) => {
    return <div className="component__dashboardTable">
        <div className="component__dashboardTable__head">
            {props.headers?.map((item, index) => {
                return <p className="component__dashboardTable__head__single" key={index}>
                    {item}
                </p>
            })}
        </div>

        {props.content !== 'error' && props.content !== 'spinner' && props.content?.map((item, index) => {
            return <div style={{background: window.innerWidth <= 1270 && index % 2 === 0 ? '#ebeaea' : window.innerWidth <= 1270 && index % 2 === 1 ? '#fcf9f9' : ''}} className="component__dashboardTable__content" key={index}>
                <p className="component__dashboardTable__content__single" >
                    {window.innerWidth <= 1270 && <strong>Transaction Hash: </strong>} {item.TransactionHash}
                </p>
                <p className="component__dashboardTable__content__single" >
                    {window.innerWidth <= 1270 && <strong>OBC Amount: </strong>} {item.OBCAmount}
                </p>
                <p
                    style={{ color: item.Status === 0 ? '#b48a0a' : item.Status === 1 ? '#2da00f' : item.Status === 2 ? '#b52e14' : 'gray' }}
                    className="component__dashboardTable__content__single"
                >
                    {window.innerWidth <= 1270 && <strong>Status: </strong>} {item.Status === 0 ? 'Pending' : item.Status === 1 ? 'Approved' : item.Status === 2 ? 'Rejected' : 'Undefined status'}
                </p>
                <p className="component__dashboardTable__content__single" >
                    {window.innerWidth <= 1270 && <strong>Type: </strong>} {item.Type}
                </p>
                <p className="component__dashboardTable__content__single" >
                {window.innerWidth <= 1270 && <strong>Date: </strong>} {moment(item.createdAt).format('DD.MM.YYYY.')}
                </p>
            </div>
        })}
        {props.content === 'error' && <br></br>}
        {props.content === 'error' && <p style={{ color: 'red' }}>Can not get transactions data</p>}
        {props.content === 'spinner' && <Spinner align='center' color='#5956E9' style={{ marginTop: '30px' }} />}
        {props.content.length === 0 && <p style={{ marginTop: '20px' }}>There is nothing to show</p>}
    </div>
}
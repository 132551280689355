import {HashRouter as Router, Routes, Route} from "react-router-dom";

import Header from "./components/Header";
import ContentWrapper from "./components/ContentWrapper";
import DashboardLanding from "./routes/DashboardLanding";

import LandingPage from "./routes/LandingPage";
import TermsOfService from "./routes/TermsOfService";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import TokenSaleAgreement from "./routes/TokenSaleAgreement";
import LegalNotice from "./routes/LegalNotice";
import Login from "./routes/Login";
import Counter from "./components/Counter";

const App = () => {
  return <Router>
    <Counter />
    <Header />
    <ContentWrapper>
      <Routes>

        <Route path="/" element={<LandingPage />} />
        <Route path="/dashboard" element={<DashboardLanding />} />
        <Route path="/dashboard/*" element={<DashboardLanding />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/tokensaleagreement" element={<TokenSaleAgreement />} />
        <Route path="/legalnotice" element={<LegalNotice />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </ContentWrapper>
  </Router>
};

export default App;

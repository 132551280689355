import React from "react";
import "./index.scss";

import moment from "moment";

const counterData = [
    {
        name: "Sale begins in",
        startDate: moment().add(-1, "year"),
        endDate: moment("15.11.2023", "DD.MM.YYYY").startOf("day")
    },
    {
        name: "Phase 1 ends in",
        startDate: moment("14.11.2023", "DD.MM.YYYY").endOf("day"),
        endDate: moment("15.12.2023", "DD.MM.YYYY").startOf("day")
    },
    {
        name: "Phase 2 ends in",
        startDate: moment("14.12.2023", "DD.MM.YYYY").endOf("day"),
        endDate: moment("15.01.2024", "DD.MM.YYYY").startOf("day")
    },
    {
        name: "Phase 3 ends in",
        startDate: moment("14.01.2024", "DD.MM.YYYY").endOf("day"),
        endDate: moment("15.02.2024", "DD.MM.YYYY").startOf("day")
    },
    {
        name: "Phase 4 ends in",
        startDate: moment("4.02.2024", "DD.MM.YYYY").endOf("day"),
        endDate: moment("31.03.2024", "DD.MM.YYYY").startOf("day")
    },
]

let curTimeout = null;
const Counter = () => {
    const [intervals, setIntervals] = React.useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
        days: 0
    });

    const findNearestDate = () => {
        let cur = moment();
        let final = null;

        for (let item of counterData) {
            if (cur.isAfter(item.startDate) && cur.isBefore(item.endDate)) {
                final = item;
                break;
            };
        };

        if (!final) final = counterData[counterData.length-1];
        if (final.endDate.isBefore(moment())) final = counterData[counterData.length-1];
        return final;
    };

    let curDate = findNearestDate();

    React.useEffect(() => {
        let handleTimeout = () => {
            let finalInterval = Math.floor((curDate.endDate.toDate().getTime() - Date.now()) / 1000);

            let days = 0;
            let hours = 0;
            let minutes = 0;
            let seconds = finalInterval;

            while (seconds > 60) {
                minutes += 1;
                seconds -= 60;
            };
            while (minutes > 60) {
                hours += 1;
                minutes -= 60;
            };
            while (hours > 23) {
                hours -= 24;
                days += 1;
            };
            setIntervals({
                days,
                hours,
                minutes,
                seconds
            })

            setTimeout(handleTimeout, 500 );
        };
        setTimeout(handleTimeout, 0);

        return () => clearTimeout(curTimeout)
    }, []);

    return <div className="route__counter">
        <p className="route__counter__text">{curDate.name}</p>
        <p className="route__counter__count">
            <span className="route__counter__count__value">{intervals.days}</span>
            <span className="route__counter__count__name">DAYS</span>
        </p>
        <p className="route__counter__count">
            <span className="route__counter__count__value">{intervals.hours}</span>
            <span className="route__counter__count__name">HOURS</span>
        </p>
        <p className="route__counter__count">
            <span className="route__counter__count__value">{intervals.minutes}</span>
            <span className="route__counter__count__name">MINUTES</span>
        </p>
        <p className="route__counter__count">
            <span className="route__counter__count__value">{intervals.seconds}</span>
            <span className="route__counter__count__name">SECONDS</span>
        </p>
    </div>
};

export default Counter;
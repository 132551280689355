import React from "react";
import "./index.scss";

import { useLocation, useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

import { ReactComponent as Icon_dashboard } from "./icons/sidebar_dashboard.svg";
import { ReactComponent as Icon_referrals } from "./icons/sidebar_referrals.svg";
import { ReactComponent as Icon_admin } from "./icons/sidebar_admin.svg";

const Sidebar = (props) => {
    const [active, setActive] = React.useState(false)
    const curLocation = useLocation();
    const curNavigate = useNavigate();

    const animateNavigate = to => {
        if (curLocation.pathname !== to) {
            animateModule(curNavigate, to, document.querySelector(".route__dashboardLanding__wrap"));
        };
    };

    return <div className={!active ? "component__sidebar" : "component__sidebar component__sidebar--active"}>
        <img className="component__sidebar__logo" onClick={() => setActive(!active)} alt="" src="/images/logo.svg" />
        <div className="component__sidebar__mobile">
            <img onClick={() => setActive(!active)} alt="" src="/images/logo.svg" />
            <img onClick={() => setActive(!active)} className="component__sidebar__mobile__hamburger" src="images/header_hamburger.svg" alt="" />
        </div>
        <div onClick={() => { animateNavigate("/dashboard"); setActive(!active) }} className={`component__sidebar__menuItem ${curLocation.pathname === "/dashboard" ? "component__sidebar__menuItem--active" : ""}`}>
            <div className="component__sidebar__menuItem__border"></div>
            <Icon_dashboard />
            <p>Dashboard</p>
        </div>
        {props?.userData?.Referral && <div onClick={() => { animateNavigate("/dashboard/referrals"); setActive(!active) }} className={`component__sidebar__menuItem ${curLocation.pathname === "/dashboard/referrals" ? "component__sidebar__menuItem--active" : ""}`}>
            <div className="component__sidebar__menuItem__border"></div>
            <Icon_referrals />
            <p>Referrals</p>
        </div>}
        {props?.userData?.isAdmin && <div onClick={() => { animateNavigate("/dashboard/adminreferrals"); setActive(!active) }} className={`component__sidebar__menuItem ${curLocation.pathname === "/dashboard/adminreferrals" ? "component__sidebar__menuItem--active" : ""}`}>
            <div className="component__sidebar__menuItem__border"></div>
            <Icon_admin />
            <p>Referrals admin</p>
        </div>}
        {props?.userData?.isAdmin && <div onClick={() => { animateNavigate("/dashboard/adminsettings"); setActive(!active) }} className={`component__sidebar__menuItem ${curLocation.pathname === "/dashboard/adminsettings" ? "component__sidebar__menuItem--active" : ""}`}>
            <div className="component__sidebar__menuItem__border"></div>
            <Icon_admin />
            <p>Settings admin</p>
        </div>}
        <div className="component__sidebar__settingsContainer">
            <div onClick={() => animateNavigate("/")} className="component__sidebar__settingsContainer__single">
                <div className="component__sidebar__menuItem__border"></div>
                <img alt="" src="/images/dashboard/sidebar_goBack.svg" />
                <p>Go back</p>
            </div>
        </div>

    </div>
};

export default Sidebar;
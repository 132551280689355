import React from 'react';
import "./index.scss";

import Contract from "../../modules/contract.json";
import { useMetaMask } from 'metamask-react';
import { useNavigate } from 'react-router-dom';
import { animateBox } from "../../modules/componentAnimation";
import animateModule from '../../modules/animateModule';

import Spinner from "../customComponents/Spinner";
import YesNoModal from "../Modals/YesNoModal";

export default function ConnectWallet() {
    const mainMetaMask = useMetaMask();
    const [metamaskSwitchOpen, setMetamaskSwitchOpen] = React.useState(false);

    const curNavigate = useNavigate();
    const mainRef = React.useRef();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));    

    const handleClick = (e) => {
        switch (mainMetaMask.status) {
            case "initializing":
            case "connecting":
                return;
            case "notConnected":
                mainMetaMask.connect().catch(() => null);
                break;
            case "unavailable":
                window.open("https://metamask.io/", "_blank");
                break;
            case "connected":
                animateNavigate("/login");
                break;
            default: break;
        };
    };

    const handleChangeNetwork = (args) => {
        args.disabledAll(true);
        args.spinner(true);

        mainMetaMask.switchChain(Contract.contractNetwork)
            .then(() => null)
            .catch(() => null)
            .finally(() => {
                args.disabledAll(false);
                args.spinner(false);
                args.close();
            });
    };

    React.useEffect(() => {
        if (!mainRef.current) return;
        if (mainMetaMask.status !== "connected") return;
        if (mainMetaMask.chainId) {
            if (mainMetaMask.chainId === Contract.contractNetwork) return;
        };
        if (!metamaskSwitchOpen) setMetamaskSwitchOpen(true);
    }, [mainMetaMask.chainId, mainRef, mainMetaMask.status === "connected"]);

    React.useEffect(() => {
        if (!metamaskSwitchOpen) return;

        animateBox({currentTarget: mainRef.current}, <YesNoModal
            heading="Change network"
            text="You are not on the official ethereum network, Do You want us to change it for you?"
            isRightButtonNormal={true}
            buttonRightCallback={handleChangeNetwork}
            buttonLeftCallback={arg => {
                arg.close();
            }}
        />)


    }, [metamaskSwitchOpen]);

    return (
        <div ref={mainRef} className="component__connectWallet" key="metamask-button" onClick={handleClick}>
            <p>{(()=>{
                //Connect Wallet
                switch (mainMetaMask.status) {
                    case "notConnected":
                        return "Connect Wallet";
                    case "initializing":
                    case "connecting":
                        return <Spinner style={{width: "16px", height: "16px"}} color="white" />
                    case "unavailable":
                        return "Install MetaMask";
                    case 'connected':
                        return "Dashboard";
                    default: break;
                };
            })()}</p>
            <div className="component__connectWallet__imgWrapper">
                <img src="images/header_wallet.svg" alt="" className="component__connectWallet__imgWrapper__img" />
            </div>
        </div>
    );
};
